import React, { useState } from "react";
import { Table, Input, Button } from "reactstrap";

const PaymentModes = ({ payments, setPayments, disableToggle }) => {
  // const [payments, setPayments] = useState([{ mode: "", amount: "" }]);

  const handleModeChange = (index, value) => {
    const newPayments = [...payments];
    newPayments[index].mode = value;
    setPayments(newPayments);
  };

  const handleAmountChange = (index, value) => {
    const newPayments = [...payments];
    newPayments[index].amount = value;
    setPayments(newPayments);
  };

  const addRow = () => {
    setPayments([...payments, { mode: "", amount: "" }]);
  };

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Payment Mode</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={index}>
              <td>
                <Input
                  disabled={disableToggle}
                  id="paymode"
                  type="text"
                  list="paymentmode"
                  name="input1"
                  value={payment.mode}
                  onChange={(e) => handleModeChange(index, e.target.value)}
                />
                <datalist id="paymentmode">
                  <option value="UPI" />
                  <option value="Cash" />
                  <option value="Credit Card" />
                  <option value="Debit Card" />
                  <option value="Cheque" />
                  <option value="Net Banking" />
                </datalist>
              </td>
              <td>
                <Input
                  disabled={disableToggle}
                  type="text"
                  value={payment.amount}
                  onChange={(e) => handleAmountChange(index, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <button onClick={addRow}>Add Row</button>
    </div>
  );
};

export default PaymentModes;
