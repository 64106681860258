import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getInventoryConsumption } from "../../../../redux/inventory";
import { Plus } from "react-feather";
import PaginatedList from "./PaginatedList";

const Consumption = ({ args }) => {
  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { userData } = auth;
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData, consumptionData } = itemsList;
  const [searchInput, setSearchInput] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [paginatedList, setPaginatedList] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState("");
  const [formData, setFormData] = useState([
    {
      item: "",
      productId: "",
      quantity: "",
      stockQty: "",
      unit: "",
      department: "",
      location: "",
      remarks: "",
      staffId: "",
      status: "",
      createdBy: userData.user,
    },
  ]);
  //  const updateInventory = (productId, qty) => {
  //    const postDataInventory = {
  //      quantity: qty,
  //    };
  //    axios
  //      .post(
  //        `${process.env.REACT_APP_CRI_API}update-inventory-qty`,
  //        postDataInventory,
  //        {
  //          params: {
  //            branch: userData.branch,
  //            productId: productId,
  //          },
  //          headers: {
  //            Authorization: `Bearer ${userData.token}`,
  //          },
  //        }
  //      )
  //      .then((res) => {
  //        if (res.status === 200) {
  //          console.log("Inventory Updated");
  //          setFormData({
  //            item: "",
  //            productId: "",
  //            quantity: "",
  //            stockQty: "",
  //            unit: "",
  //            department: "",
  //            location: "",
  //            remarks: "",
  //            staffId: "",
  //            status: "",
  //          });
  //        }
  //      })
  //      .catch((err) => console.log(err));
  //  };
  //  const stockHandler = () => {
  //    const updtQty = Number(formData.stockQty) - Number(formData.quantity);
  //    updateInventory(formData.productId, updtQty);
  //  };

  // useEffect(() => {
  // dispatch(
  //   getInventoryConsumption({
  //     token: userData.token,
  //     branch: userData.branch,
  //   })
  // );
  //}, [dispatch, userData.token, userData.branch]);

  // const handleChange = (e, colName) => {
  //   const { name, value } = e.target;
  //
  //   if (colName === "item") {
  //     const selectedOption = e.target.list.querySelector(
  //       `option[value="${value}"]`
  //     );
  //     const cost = selectedOption
  //       ? selectedOption.getAttribute("data-cost")
  //       : null;
  //
  //     const productId = selectedOption
  //       ? selectedOption.getAttribute("data-productid")
  //       : null;
  //
  //     const product = selectedOption
  //       ? selectedOption.getAttribute("data-product")
  //       : null;
  //
  //     const stockQty = selectedOption
  //       ? selectedOption.getAttribute("data-qty")
  //       : null;
  //
  //     //   rate: cost,
  //     //   qty: value ? 1 : "",
  //     //   tax: value ? taxTemp : "",
  //     //   amount: value ? rowTotal : "",
  //     //   productId,
  //     //   stockQty,
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       item: product,
  //       rate: cost,
  //       productId,
  //       stockQty,
  //     }));
  //
  //     return;
  //   }
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  //   console.log(formData);
  // };
  const handleSubmit = () => {
    if (!isFormDataValid) {
      alert("Please fill in all fields");
      return;
    }
    // Create an array of promises for form submission (add-consumption)
    const consumptionPromises = formData.map((data) => {
      return axios.post(
        `${process.env.REACT_APP_CRI_API}add-consumption`,
        data,
        {
          params: {
            branch: userData.branch,
          },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
    });

    // Use Promise.all to wait for all consumption requests
    Promise.all(consumptionPromises)
      .then((responses) => {
        // Check if all responses are successful
        const allSuccessful = responses.every((res) => res.status === 200);
        if (allSuccessful) {
          alert("Save successful");
          // After all consumption requests are successful, update the stock
          stockHandler(); // Use Promise.all in stockHandler too
        } else {
          alert("Some requests failed.");
        }
      })
      .catch((err) => {
        console.error("Error submitting consumption data:", err);
      });
  };

  // Refactor stockHandler to use Promise.all for inventory updates
  const stockHandler = () => {
    // Create an array of promises for inventory updates
    const inventoryPromises = formData.map((data) => {
      const updatedQty = Number(data.stockQty) - Number(data.quantity);
      return updateInventory(data.productId, updatedQty); // Returning a promise from updateInventory
    });

    // Use Promise.all to wait for all inventory updates
    Promise.all(inventoryPromises)
      .then((responses) => {
        const allUpdated = responses.every((res) => res.status === 200);
        if (allUpdated) {
          console.log("All inventories updated");

          resetFormData(); // Reset form data once all updates are done
          setRefreshToggle(true);
          toggle();
        } else {
          console.log("Some inventory updates failed");
        }
      })
      .catch((err) => {
        console.error("Error updating inventory:", err);
      });
  };

  // updateInventory function to return the axios request promise
  const updateInventory = (productId, qty) => {
    const postDataInventory = {
      quantity: qty,
    };
    return axios.post(
      `${process.env.REACT_APP_CRI_API}update-inventory-qty`,
      postDataInventory,
      {
        params: {
          branch: userData.branch,
          productId: productId,
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };

  // Reset form data after successful submission
  const resetFormData = () => {
    setFormData(
      formData.map(() => ({
        item: "",
        productId: "",
        quantity: "",
        stockQty: "",
        unit: "",
        department: "",
        location: "",
        remarks: "",
        staffId: "",
        status: "",
      }))
    );
  };

  const handleChange = (e, colName, index) => {
    const { name, value } = e.target;
    if (colName === "item") {
      const selectedOption = e.target.list.querySelector(
        `option[value="${value}"]`
      );
      const cost = selectedOption
        ? selectedOption.getAttribute("data-cost")
        : null;
      const productId = selectedOption
        ? selectedOption.getAttribute("data-productid")
        : null;
      const product = selectedOption
        ? selectedOption.getAttribute("data-product")
        : null;
      const stockQty = selectedOption
        ? selectedOption.getAttribute("data-qty")
        : null;
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          item: product,
          rate: cost,
          productId,
          stockQty,
        };
        return updatedFormData;
      });
      return;
    }
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      return updatedFormData;
    });
  };

  const isFormDataValid = formData.every((data) => {
    return (
      data.item &&
      data.item.trim() !== "" && // Item is mandatory
      data.quantity &&
      data.quantity.trim() !== "" && // Quantity is mandatory
      data.department &&
      data.department.trim() !== "" &&
      data.location &&
      data.location.trim() !== "" &&
      data.staffId &&
      data.staffId.trim() !== "" &&
      data.status &&
      data.status.trim() !== ""
    );
  });

  //const handleSubmit = () => {
  //  if (!isFormDataValid) {
  //    alert("Please fill in all fields");
  //    return;
  //  }

  //  axios
  //    .post(`${process.env.REACT_APP_CRI_API}add-consumption`, formData, {
  //      params: {
  //        branch: userData.branch,
  //      },
  //      headers: {
  //        Authorization: `Bearer ${userData.token}`,
  //      },
  //    })
  //    .then((res) => {
  //      if (res.status === 200) {
  //        alert("Save successful");
  //        stockHandler();
  //        dispatch(
  //          getInventoryConsumption({
  //            token: userData.token,
  //            branch: userData.branch,
  //          })
  //        );
  //      }
  //    })
  //    .catch((err) => console.log(err));
  //};

  const addRow = () => {
    setFormData([
      ...formData,
      {
        item: "",
        productId: "",
        quantity: "",
        stockQty: "",
        unit: "",
        department: "",
        location: "",
        remarks: "",
        staffId: "",
        status: "",
        createdBy: userData.user,
      },
    ]);
  };

  const removeRow = (index) => {
    setFormData((prevFormData) => {
      if (prevFormData.length > 1) {
        return prevFormData.filter((_, i) => i !== index); // Remove the row at the given index
      }
      return prevFormData; // Don't allow removal if it's the last row
    });
  };

  useEffect(() => {
    console.log(formData.length);
  }, [formData]);

  return (
    <Container>
      <Row>
        <Col>
          <Card className="mb-5 mt-2">
            <CardHeader>
              <Row className="d-flex justify-content-center">
                <Col md={4}>
                  <Input
                    type="text"
                    //  id="MRN"
                    list="mrnlist"
                    name="mrn"
                    placeholder="Search record"
                    // value={formData.mrn}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                    //    onBlur={searchMRNHandler}
                    style={{ display: "inline-block", width: "150px" }}
                  />
                  <datalist id="mrnlist"></datalist>
                  <span className="ps-3"></span>
                </Col>
                <Col md={4} style={{ textAlign: "center" }}>
                  <h4>Consumption Record</h4>
                </Col>
                <Col md={4} className="d-flex ">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggle();
                    }}
                  >
                    <Plus color="green" /> Add
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="ms-4"
                    onClick={() => {}}
                  ></div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <PaginatedList
                setPaginatedList={setPaginatedList}
                //   searchInput={searchInput}
                setShowSpinner={setShowSpinner}
                endPoint="consumption-page"
                //  endPoint2="purchase-page"
                limit={300}
                setPurchasePaginatedList={null}
                purchaseLimit={50}
                refresh={refreshToggle}
              />
              <Table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Department</th>
                    <th>Location</th>
                    <th>Remarks</th>
                    <th>Created</th>
                    <th>Staff ID</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedList.length > 0 &&
                    paginatedList
                      .filter(
                        (item) =>
                          !searchInput ||
                          item.item
                            .toLowerCase()
                            .includes(searchInput.toLowerCase()) ||
                          item.status
                            .toLowerCase()
                            .includes(searchInput.toLowerCase())
                      )
                      .map((item, index) => (
                        <tr key={index}>
                          <td>{item.item}</td>
                          <td>{item.quantity}</td>
                          <td>{item.department}</td>
                          <td>{item.location}</td>
                          <td>{item.remarks}</td>
                          <td>
                            {new Date(item.createdAt).toLocaleDateString()}
                          </td>
                          <td>{item.staffId}</td>
                          <td>{item.status}</td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={modal}
        toggle={toggle}
        {...args}
        style={{ maxWidth: "800px", margin: "0 auto" }}
      >
        <ModalHeader toggle={toggle}>Add Item</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Card className="mb-5">
                <CardBody>
                  {formData.map((formData, index) => (
                    <div key={index}>
                      <Row>
                        <Col md={3}>
                          <Label for={`item-${index}`}>Item</Label>
                          <Input
                            type="text"
                            list="itemdata"
                            id={`item-${index}`}
                            name="item"
                            value={formData.item}
                            onChange={(e) => handleChange(e, "item", index)}
                          />
                          <datalist id="itemdata">
                            {itemsData.length &&
                              itemsData.map((item, id) => (
                                <option
                                  key={id}
                                  value={`${item.item} (${item.quantity})`} // replaced item.productId
                                  data-product={item.item}
                                  data-cost={item.mrp}
                                  data-productid={item.productId}
                                  data-qty={item.quantity}
                                />
                              ))}
                          </datalist>
                        </Col>
                        <Col md={3}>
                          <Label for={`quantity-${index}`}>Quantity</Label>
                          <Input
                            type="number"
                            name="quantity"
                            id={`quantity-${index}`}
                            value={formData.quantity}
                            onChange={(e) => handleChange(e, null, index)}
                            placeholder="Enter quantity"
                          />
                        </Col>
                        <Col md={3}>
                          <Label for={`unit-${index}`}>Unit</Label>
                          <Input
                            type="text"
                            name="unit"
                            id={`unit-${index}`}
                            value={formData.unit}
                            onChange={(e) => handleChange(e, null, index)}
                            placeholder="Enter unit"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label for={`department-${index}`}>Department</Label>
                          <Input
                            type="text"
                            name="department"
                            id={`department-${index}`}
                            value={formData.department}
                            onChange={(e) => handleChange(e, null, index)}
                            placeholder="Enter department"
                          />
                        </Col>
                        <Col md={6}>
                          <Label for={`location-${index}`}>Location</Label>
                          <Input
                            type="text"
                            name="location"
                            id={`location-${index}`}
                            value={formData.location}
                            onChange={(e) => handleChange(e, null, index)}
                            placeholder="Enter location"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label for={`remarks-${index}`}>Remarks</Label>
                          <Input
                            type="textarea"
                            name="remarks"
                            id={`remarks-${index}`}
                            value={formData.remarks}
                            onChange={(e) => handleChange(e, null, index)}
                            placeholder="Enter remarks"
                          />
                        </Col>
                        <Col md={6}>
                          <Label for={`staffId-${index}`}>Staff ID</Label>
                          <Input
                            type="text"
                            name="staffId"
                            id={`staffId-${index}`}
                            value={formData.staffId}
                            onChange={(e) => handleChange(e, null, index)}
                            placeholder="Enter staff ID"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label for={`status-${index}`}>Status</Label>
                          <Input
                            type="text"
                            name="status"
                            id={`status-${index}`}
                            value={formData.status}
                            onChange={(e) => handleChange(e, null, index)}
                            placeholder="Enter status"
                          />
                        </Col>
                        <Col md={3} className="d-flex align-items-center">
                          <button type="button" onClick={addRow}>
                            +
                          </button>
                          <button
                            type="button"
                            onClick={() => removeRow(index)}
                          >
                            -
                          </button>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  ))}
                  <Row className="justify-content-end pt-3">
                    <Col md={2} style={{ textAlign: "right" }}>
                      <Button color="primary" onClick={handleSubmit}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default Consumption;
