import axios from "axios";
import { useState, useMemo, React, useEffect, Fragment, useRef } from "react";
import "../CRM/styles/style.css";
import validator from "validator";
import { useReactToPrint } from "react-to-print";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import ci from "../../../images/cutis_new.jpeg";
import {
  getCustomers,
  getInventoryItems,
  getPrescriptions,
  getProcedures,
  getbranchData,
} from "../../../Data/Customer/api";
import { Search } from "react-feather";
import { numberToWords } from "./utils/numberWords";

const Pharmacy = ({ direction, ...args }) => {
  const token = sessionStorage.getItem("token")
    ? JSON.parse(sessionStorage.getItem("token"))
    : "";
  const componentRef = useRef();
  const navigate = useNavigate();
  const [username] = useState(JSON.parse(sessionStorage.getItem("username")));
  const [modal, setModal] = useState(false);
  const data = "";
  const date = new Date();
  const dateNew = new Date();
  const datewithoutHours = new Date(dateNew.setHours(0, 0, 0, 0)).toISOString();
  const [procedures, setProcedures] = useState("");
  // const utcDate = date.toISOString(); // saving pupose, always put this before date.setHours, which modifies date itself
  const mdate = date;
  // const startDay = new Date(mdate.setUTCHours(0, 0, 0, 0)).toISOString(); // searching purpose

  let mrn = "";
  //const invoice_date = utcDate;
  const time = date.getTime();
  const branch = JSON.parse(sessionStorage.getItem("branch"));
  const [payment_mode, setPayment_mode] = useState("cash");
  const invoice_id = `INV-${Date.now()}`;
  const status = "";
  const remarks = "";
  const [buttonHide, setButtonHide] = useState({ display: "normal" });
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [address, setAddress] = useState("");
  const [addressPrint, setAddressPrint] = useState("");
  const [total, setTotal] = useState(0);
  const [netPayable, setNetPayable] = useState("");
  const total_amount = total;

  const [discount, setDiscount] = useState("");
  const [totalSGST, setTotalSGST] = useState("");
  const [totalCGST, setTotalCGST] = useState("");
  const [description, setDescription] = useState([
    "DERMAT CONSULTATION",
    "HAIRLOSS CONSULTATION",
    "PRP",
    "GFC",
    "BIOTIN PRP",
    "HAIR TRANSPLANT",
  ]);
  const [inputValues, setInputValues] = useState({
    input1: 0,
    input2: 0,
    input3: 0,
    input4: 0,
    input5: 0,
    input6: 0,
  });
  const [phmcyInvoiceSheet, setPhmcyInvoiceSheet] = useState([
    {
      Row1: {
        item: "",
        HSN_SAC: "",
        qty: "",
        unitPrice: 0,
        SGST: 0,
        CGST: 0,
        tax: 0,
        subTotal: 0,
      },
    },
    {
      Row2: {
        item: "",
        HSN_SAC: "",
        qty: "",
        unitPrice: 0,
        SGST: 0,
        CGST: 0,
        tax: 0,
        subTotal: 0,
      },
    },
    {
      Row3: {
        item: "",
        HSN_SAC: "",
        qty: "",
        unitPrice: 0,
        SGST: 0,
        CGST: 0,
        tax: 0,
        subTotal: 0,
      },
    },
    {
      Row4: {
        item: "",
        HSN_SAC: "",
        qty: "",
        unitPrice: 0,
        SGST: 0,
        CGST: 0,
        tax: 0,
        subTotal: 0,
      },
    },
    {
      Row5: {
        item: "",
        HSN_SAC: "",
        qty: "",
        unitPrice: 0,
        SGST: 0,
        CGST: 0,
        tax: 0,
        subTotal: 0,
      },
    },
    {
      Row6: {
        item: "",
        HSN_SAC: "",
        qty: "",
        unitPrice: 0,
        SGST: 0,
        CGST: 0,
        tax: 0,
        subTotal: 0,
      },
    },
  ]);

  const invoice_sheet = phmcyInvoiceSheet;
  const toggle = () => setModal(!modal);
  const [cmName, setCmName] = useState("");
  const [gender, setGender] = useState("");
  const [selectedMrn, setSelectedMrn] = useState(""); // Assuming it's a number, change as needed
  const [contactNo, setContactNo] = useState("");
  const [nationality, setNationality] = useState("");
  const [dob, setDOB] = useState("");
  const [age, setAge] = useState("");
  const [doctor, setDoctor] = useState("");
  const [prescript, setPrescript] = useState("");
  const [saving, setSaving] = useState(false);
  const [recievedAmt, setRecievedAmt] = useState("");
  const [balanceAmount, setBalanceAmount] = useState("");
  const total_amount_paid = recievedAmt;
  const [inventoryItems, setInventoryItems] = useState("");

  const searchHandler = (category) => {
    const dt = new Date();
    //console.log(branch, mrn);
    //token, branch, mrn, date, navigate
    if (category === "Prescriptions") {
      // for loading mrns for selecting cm from prescription
      getPrescriptions(token, branch, mrn, datewithoutHours, navigate)
        .then((res) => {
          setPrescript(res);
        })
        .catch((err) => console.log(err));
    } else if (category === "Customer") {
      // token,
      //  branch,
      //mrn,
      // doctorName,
      // date,
      // navigate
      getCustomers(token, branch, selectedMrn, "", "", navigate)
        .then((res) => {
          //console.log(res);
          // setSelectedCM(res);
          if (res.length > 0) {
            setCmName(res[0].fname + " " + res[0].sname);
            setGender(res[0].sex);
            setContactNo(res[0].contactno);
            setNationality(res[0].nationality);
            setDOB(res[0].dob);
            setAge(res[0].age);
          }
        })
        .catch((err) => {
          alert("Verify the MRN");
          console.log(err);
        });
    }
  };
  const handleDefaultInputProcedure = (e, rowIndex, colName) => {
    const { name, value, hsn, cost } = e;

    const numericValue = value === null || value === "" ? 0 : parseFloat(value);
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: numericValue,
    }));

    //console.log(inputValues);
    const sgstTemp = ((Number(cost) * 6) / 100).toFixed(2);
    const cgstTemp = ((Number(cost) * 6) / 100).toFixed(2);
    const taxTemp = ((Number(cost) * 12) / 100).toFixed(2);

    //   handleInputChange(e, index, colName);
    const rowTotal = ((parseFloat(taxTemp) + parseFloat(cost)) * 1)
      // parseFloat(colName[`Row${rowIndex + 1}`].qty)
      .toFixed(2);

    //console.log(rowTotal);
    // direct update

    setPhmcyInvoiceSheet((prevInvoiceSheet) => {
      return prevInvoiceSheet.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [`Row${rowIndex + 1}`]: {
              ...row[`Row${rowIndex + 1}`],
              [colName]: value,
              HSN_SAC: hsn,
              unitPrice: cost,
              qty: 1,
              SGST: sgstTemp,
              CGST: cgstTemp,
              tax: taxTemp,
              subTotal: rowTotal,
            },
          };
        }
        return row;
      });
    });
  };

  useEffect(() => {
    if (
      selectedMrn &&
      Array.isArray(prescript) &&
      prescript.length === 1 &&
      Array.isArray(inventoryItems)
    ) {
      prescript.map((pres, index) => {
        setDoctor(pres.doctorName);
        if (pres.mrn === selectedMrn) {
          const item = Array.isArray(pres.prescription)
            ? pres.prescription
                .map((pr, id) => {
                  if (id === index) return pr.label;
                })
                .join(", ")
            : "";
          const unitPrice = Array.isArray(pres.prescription)
            ? pres.prescription
                .map((pr) => {
                  const item = inventoryItems.find((pd) => pd._id === pr.value);

                  return item ? item.Unit_Price : null;
                })
                .filter((hsn) => hsn !== null) // Filter out null values
                .join(", ")
            : "";
          const hsn = Array.isArray(pres.prescription)
            ? pres.prescription
                .map((pr) => {
                  const item = inventoryItems.find((pd) => pd._id === pr.value);

                  return item ? item.HSN : null;
                })
                .filter((hsn) => hsn !== null) // Filter out null values
                .join(", ")
            : "";
          handleDefaultInputProcedure(
            { name: item, value: item, hsn, cost: unitPrice },
            index,
            "item"
          );
        }
      });

      //console.log(prescript.find((item) => selectedMrn === item.mrn));
    } else if (
      selectedMrn &&
      Array.isArray(prescript) &&
      prescript.length > 1 &&
      Array.isArray(inventoryItems)
    ) {
      prescript.map((pres, index) => {
        if (pres.mrn === selectedMrn) {
          const item = Array.isArray(pres.prescription)
            ? pres.prescription
                .map((pr, id) => {
                  if (id === index) return pr.label;
                })
                .join(", ")
            : "";
          const unitPrice = Array.isArray(pres.prescription)
            ? pres.prescription
                .map((pr, id) => {
                  return pr.unit_price;
                })
                .join(", ")
            : "";
          handleDefaultInputProcedure(
            { name: item, value: item, cost: unitPrice },
            index,
            "item"
          );
          //    setPhmcyInvoiceSheet((prevInvoiceSheet) => {
          //      return prevInvoiceSheet.map((row, index) => {
          //        //   if (index === 0) {
          //        return {
          //          ...row,
          //          [`Row${index + 1}`]: {
          //            ...row[`Row${index + 1}`],
          //            item: Array.isArray(pres.prescription)
          //              ? pres.prescription
          //                  .map((pr, id) => {
          //                    if (id === index) return pr.label;
          //                  })
          //                  .join(", ")
          //              : "",
          //            HSN_SAC: Array.isArray(pres.prescription)
          //              ? pres.prescription
          //                  .map((pr, id) => {
          //                    return inventoryItems.filter(
          //                      (pd) => pd._id === pr._id
          //                    )[0].hsn;
          //                    // return pr.hsn;
          //                  })
          //                  .join(", ")
          //              : "",
          //            qty: "",
          //            unitPrice: Array.isArray(pres.prescription)
          //              ? pres.prescription
          //                  .map((pr, id) => {
          //                    return pr.unit_price;
          //                  })
          //                  .join(", ")
          //              : "",
          //            tax: 0,
          //            subTotal: 0,
          //          },
          //        };
          //        //    }
          //        //     return row;
          //      });
          //    });
        }
      });
    }
    // console.log(prescriptOptions);
  }, [prescript, selectedMrn, inventoryItems]);

  useEffect(() => {
    if (branch && token) {
      getProcedures(token, branch, navigate).then((res) => {
        // console.log(res);
        setProcedures(res.data);
      });

      searchHandler("Prescriptions"); // for getting mrns for today to help auto fill the mrn search and accuracy

      getInventoryItems(token, branch, navigate).then((res) => {
        if (res.data) {
          setInventoryItems(res.data);
        }
      });
    }

    const fetchBranchData = async () => {
      //console.log(branch, token);
      if (branch && token) {
        try {
          // or const respone = await getbranchData(branch);
          //console.log(response)

          getbranchData(token, branch, navigate).then((res) => {
            //console.log(res);
            setAddressPrint(res);
            setAddress(() => {
              return (
                <div className=" pt-2">
                  <span className="mb-0" style={{ fontSize: "14px" }}>
                    <b>{res[0].address.first_line}</b>
                  </span>
                  <br />
                  <span style={{ fontSize: "14px" }}>
                    {res[0].address.second_line}
                  </span>{" "}
                  <br />
                  <span style={{ fontSize: "14px" }}>
                    {res[0].address.third_line}
                  </span>
                  <br />
                  <span style={{ fontSize: "14px" }}>
                    {res[0].address.fourth_line}
                  </span>
                  <br />
                  <b>GSTIN :</b>
                  <span className="ps-2">{res[0].GSTIN} </span>
                </div>
              );
            });
            sessionStorage.setItem("address", JSON.stringify(res));
          });
        } catch (error) {
          console.log(error.message);
        }
      }
    };
    fetchBranchData();
    if (!token) {
      navigate("/");
    }
  }, [branch, token]);

  const calculateTotal = () => {
    const values = phmcyInvoiceSheet
      .map((row, index) => row[`Row${index + 1}`].subTotal)
      .filter((i) => parseFloat(i));
    //const values = Object.values(inputValues).map((value) => parseFloat(value));
    const totalTemp = values.reduce((total, row) => {
      //const value = Object.values(row)[0].Col5;
      return parseFloat(total) + parseFloat(row);
      // console.log(row);
    }, 0);
    //console.log(values, totalTemp);
    setTotal(parseFloat(totalTemp));

    const sgstValues = phmcyInvoiceSheet
      .map((row, index) => row[`Row${index + 1}`].SGST)
      .filter((i) => parseFloat(i));
    //const values = Object.values(inputValues).map((value) => parseFloat(value));
    const sgstTemp = sgstValues.reduce((total, row) => {
      //const value = Object.values(row)[0].Col5;
      return parseFloat(total) + parseFloat(row);
      // console.log(row);
    }, 0);

    setTotalSGST(sgstTemp);
    const cgstValues = phmcyInvoiceSheet
      .map((row, index) => row[`Row${index + 1}`].CGST)
      .filter((i) => parseFloat(i));
    const cgstTemp = cgstValues.reduce((total, row) => {
      return parseFloat(total) + parseFloat(row);
    }, 0);

    setTotalCGST(sgstTemp);
    //  return total;
  };

  useEffect(() => {
    if (phmcyInvoiceSheet) {
      //console.log(phmcyInvoiceSheet);
      calculateTotal();
    }
    //if (inputValues) calculateTotal();
  }, [data, phmcyInvoiceSheet, calculateTotal]);

  useEffect(() => {
    if (total) {
      const discountTemp = discount ? discount : 0;
      setNetPayable(
        () => parseFloat(total) - parseFloat(discountTemp).toFixed(0)
      );
    }
  }, [total, discount]);

  useEffect(() => {
    if (recievedAmt) {
      setBalanceAmount(() => parseFloat(netPayable) - parseFloat(recievedAmt));
    }
  }, [recievedAmt]);

  const handleInputProcedure = (e, rowIndex, colName) => {
    const { name, value } = e.target;
    const selectedOption = e.target.list.querySelector(
      `option[value="${value}"]`
    );
    const cost = selectedOption
      ? selectedOption.getAttribute("data-cost")
      : null;

    // setTotal(cost);
    // console.log();
    //console.log(parseFloat(value));
    // Convert null or empty string to 0
    const numericValue = value === null || value === "" ? 0 : parseFloat(value);
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: numericValue,
    }));

    //console.log(inputValues);
    const sgstTemp = ((Number(cost) * 6) / 100).toFixed(2);
    const cgstTemp = ((Number(cost) * 6) / 100).toFixed(2);
    const taxTemp = ((Number(cost) * 12) / 100).toFixed(2);

    //   handleInputChange(e, index, colName);
    const rowTotal = ((parseFloat(taxTemp) + parseFloat(cost)) * 1)
      // parseFloat(colName[`Row${rowIndex + 1}`].qty)
      .toFixed(2);
    //console.log(rowTotal);
    // direct update

    setPhmcyInvoiceSheet((prevInvoiceSheet) => {
      //console.log(date, invoice_date);
      return prevInvoiceSheet.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [`Row${rowIndex + 1}`]: {
              ...row[`Row${rowIndex + 1}`],
              [colName]: value,
              unitPrice: cost,
              qty: 1,
              SGST: sgstTemp,
              CGST: cgstTemp,
              tax: taxTemp,
              subTotal: rowTotal,
            },
          };
        }
        return row;
      });
    });
  };

  const handleInputChange = (e, rowIndex, colName) => {
    const { name, value } = e.target;
    //console.log(parseFloat(value));
    // Convert null or empty string to 0
    const numericValue = value === null || value === "" ? 0 : parseFloat(value);
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: Math.sign(numericValue),
    }));

    //console.log(inputValues);

    setPhmcyInvoiceSheet((prevInvoiceSheet) => {
      //console.log(date, invoice_date);
      return prevInvoiceSheet.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [`Row${rowIndex + 1}`]: {
              ...row[`Row${rowIndex + 1}`],
              [colName]: value,
            },
          };
        }
        return row;
      });
    });
  };
  // 3535 ron bus
  // km 65728 km 28-aug-23
  const postData = {
    mrn: selectedMrn,
    branch,
    invoice_date: datewithoutHours,
    time,
    total_amount,
    payment_mode,
    total_amount_paid,
    invoice_id,
    invoice_sheet,
    status,
    remarks,
    age,
    createdBy: username,
    discount,
    balance_amount: balanceAmount,
  };

  const submitHandler = () => {
    setSaving(true);
    axios
      .post(`${process.env.REACT_APP_CRI_API}add-phmcyinvoice`, postData, {
        params: {
          branch: branch,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          alert("Save Successful");
        }
        setSaving(false);
      })
      .catch((err) => {
        if (
          (err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      });
  };
  //  useEffect(() => {
  //    if (searchInput) {
  //      const v = search.filter((cm) => {
  //        // console.log(cm);
  //        return `${cm.contactno} ${cm.fname} ${cm.mrn}`
  //          .toLowerCase()
  //          .includes(searchInput.trim().toLowerCase());
  //      });
  //      setSearchResults(v);
  //    }
  //  }, [searchInput]);

  const clearEntry = () => {
    setCmName("");
    setGender("");
    setContactNo("");
    setDoctor("");
    setNationality("");
    setPhmcyInvoiceSheet((prevInvoiceSheet) => {
      return prevInvoiceSheet.map((row, index) => {
        if (index === 0) {
          return {
            ...row,
            [`Row${index + 1}`]: {
              ...row[`Row${index + 1}`],
              item: "",
            },
          };
        }
        return row;
      });
    });
  };

  const qtyHandler = (e, index, colName, row) => {
    const value = e.target.value;
    const num = validator.isNumeric(value, {
      no_symbols: false,
    });
    const decimal = validator.isFloat(value, {
      no_symbols: false,
    });
    if (value !== "-" && (decimal || num || value === "")) {
      // const taxTemp = ((Number(e.target.value) * 12) / 100).toFixed(2);
      const taxTemp = row[`Row${index + 1}`].tax;
      handleInputChange(e, index, colName);

      if (row[`Row${index + 1}`].unitPrice) {
        const rowTotal = (
          (parseFloat(taxTemp) + parseFloat(row[`Row${index + 1}`].unitPrice)) *
          parseFloat(e.target.value)
        ).toFixed(2);
        //console.log(rowTotal);
        // direct update
        setPhmcyInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, rindex) => {
            if (rindex === index) {
              return {
                ...row,
                [`Row${index + 1}`]: {
                  ...row[`Row${index + 1}`],
                  subTotal: rowTotal,
                },
              };
            }
            return row;
          });
        });
      } else {
        setPhmcyInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, rindex) => {
            if (rindex === index) {
              return {
                ...row,
                [`Row${index + 1}`]: {
                  ...row[`Row${index + 1}`],
                  qty: parseFloat(e.target.value),
                },
              };
            }
            return row;
          });
        });
      }
    } else {
      alert("Enter Numeric Value");
    }
  };

  const unitPriceHandler = (e, index, colName, row) => {
    const value = e.target.value;
    const num = validator.isNumeric(value, {
      no_symbols: false,
    });
    const decimal = validator.isFloat(value, {
      no_symbols: false,
    });
    if (value === "-" || decimal || num || value === "") {
      const taxTemp = ((Number(e.target.value) * 12) / 100).toFixed(2);

      handleInputChange(e, index, colName);

      if (row[`Row${index + 1}`].qty) {
        const rowTotal = (
          (parseFloat(taxTemp) + parseFloat(e.target.value)) *
          parseFloat(row[`Row${index + 1}`].qty)
        ).toFixed(2);
        //console.log(rowTotal);
        // direct update
        setPhmcyInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, rindex) => {
            if (rindex === index) {
              return {
                ...row,
                [`Row${index + 1}`]: {
                  ...row[`Row${index + 1}`],
                  tax: taxTemp,
                  subTotal: rowTotal,
                },
              };
            }
            return row;
          });
        });
      } else {
        setPhmcyInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, rindex) => {
            if (rindex === index) {
              return {
                ...row,
                [`Row${index + 1}`]: {
                  ...row[`Row${index + 1}`],
                  tax: taxTemp,
                },
              };
            }
            return row;
          });
        });
      }
    } else {
      alert("Enter Numeric Value");
    }
  };

  return (
    <Container className="pt-2 pb-0">
      <Row className="d-flex justify-content-between pt-2 ps-5 ">
        <Col md={3}>
          <InputGroup className="mb-4">
            <Input
              placeholder="search MRN"
              list="mrnlist"
              onChange={(e) => {
                clearEntry();
                const mrnNum = validator.isNumeric(e.target.value);
                if (mrnNum) {
                  setSelectedMrn(e.target.value);
                } else if (e.target.value === "") {
                  alert("Please enter MRN ");
                }
              }}
            />
            <datalist id="mrnlist">
              {prescript &&
                prescript.map((item, key) => (
                  <option key={key} value={item.mrn} />
                ))}
            </datalist>

            <InputGroupText className="bg-primary">
              {" "}
              <Search
                onClick={() => {
                  searchHandler("Customer");
                  //  setModalName("search");
                  // toggle();
                }}
                style={{ cursor: "pointer" }}
              />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col md={2}>User: {username}</Col>
      </Row>
      <div className="px-5">
        <Row className="pt-0 bg-white">
          <Col md="4" sm={3}>
            <img src={ci} alt="image" style={{ height: "100px" }} />
          </Col>
          <Col md={5} sm={5} className="d-flex justify-content-center">
            {address}
          </Col>
          <Col md="3" className="pt-2">
            <h6>
              <b>Invoice</b>
            </h6>
            <span style={{ fontSize: "14px" }}>Invoice No:{invoice_id}</span>
            <br />
            <span style={{ fontSize: "14px" }}>
              Date : {formatDate(date)}
            </span>{" "}
            <br />
            <span style={{ fontSize: "14px" }}>Doctor:</span>
            <Input
              value={doctor}
              style={{ display: "inline", width: "200px" }}
              onChange={(e) => setDoctor(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="pt-5">
          <Col sm={4} className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <h6>Patient Name :</h6>
              <Input
                style={{ display: "inline", width: "250px" }}
                value={cmName}
                onChange={(e) => setCmName(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-between">
              <h6>Gender :</h6>
              <Input
                type="text"
                list="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                style={{ display: "inline", width: "250px" }}
              />
              <datalist>
                <option>male</option>
                <option>female</option>
              </datalist>
            </div>
            <div className="d-flex justify-content-between">
              <h6>MRN :</h6>
              <Input
                style={{ display: "inline", width: "250px" }}
                readOnly
                value={selectedMrn}
              />
            </div>
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <h6>Contact No :</h6>
              <Input
                type="text"
                value={contactNo}
                style={{ display: "inline", width: "250px" }}
                onChange={(e) => setContactNo(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-between">
              <h6>Nationality :</h6>
              <Input
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                style={{ display: "inline", width: "250px" }}
              />
            </div>
            <div className="d-flex justify-content-between">
              <h6>Branch :</h6>
              <Input
                value={branch}
                readOnly
                style={{ display: "inline", width: "250px" }}
              />
            </div>
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <div className="d-flex justify-content-start">
              <h6>DOB :</h6>
              <Input
                type="text"
                value={dob}
                style={{
                  display: "inline",
                  width: "250px",
                  marginLeft: "40px",
                }}
                onChange={(e) => setDOB(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-start">
              <h6>Age : </h6>
              <Input
                type="text"
                value={age}
                style={{
                  display: "inline",
                  width: "250px",
                  marginLeft: "45px",
                }}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center pt-2">
          <Col md="2">
            <h4 style={{ textAlign: "center" }}>Reciept {}</h4>
          </Col>
        </Row>
        <Table style={{ backgroundColor: "blue" }} bordered>
          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "5%",
                }}
              >
                Sl. No
              </th>
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "30%",
                  textAlign: "center",
                }}
              >
                Items/Services
              </th>
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  // width: "10%",
                }}
              >
                HSN/SAC/Batch/Expiry
              </th>
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "10%",
                }}
              >
                Qty
              </th>
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "10%",
                }}
              >
                {" "}
                Rate
              </th>
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "15%",
                }}
              >
                Tax
              </th>

              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "10%",
                }}
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {phmcyInvoiceSheet.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Input
                      type="list"
                      list="procedures"
                      id="Row1Item"
                      //  readOnly={MRN !== ""}
                      value={row[`Row${index + 1}`].item}
                      onChange={(e) => handleInputProcedure(e, index, "item")}
                    />
                    <datalist id="procedures">
                      {procedures &&
                        procedures.map((item, key) => (
                          <option
                            key={key}
                            value={item.procedure}
                            data-cost={item.cost}
                          />
                        ))}
                    </datalist>
                  </td>
                  <td>
                    {row[`Row${index + 1}`].HSN_SAC
                      ? row[`Row${index + 1}`].HSN_SAC
                      : ""}
                  </td>
                  <td>
                    <Input
                      type="text"
                      id="Row1Qty"
                      value={
                        row[`Row${index + 1}`].qty
                          ? row[`Row${index + 1}`].qty
                          : ""
                      }
                      onChange={(e) => {
                        qtyHandler(e, index, "qty", row);
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      id="Row1UnitPrice"
                      type="text"
                      name="input1"
                      value={
                        row[`Row${index + 1}`].unitPrice
                          ? row[`Row${index + 1}`].unitPrice
                          : ""
                      }
                      onChange={(e) =>
                        unitPriceHandler(e, index, "unitPrice", row)
                      }
                    />
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className="pe-3 pt-2">
                        {row[`Row${index + 1}`].tax
                          ? row[`Row${index + 1}`].tax
                          : ""}
                      </div>
                      {row[`Row${index + 1}`].tax && (
                        <div style={{ fontSize: "12px" }}>
                          SGST - {row[`Row${index + 1}`].SGST} <br />
                          CGST - {row[`Row${index + 1}`].CGST}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <Input
                      id={row[`Row${index + 1}`].subTotal}
                      type="text"
                      name="input1"
                      readOnly
                      value={
                        row[`Row${index + 1}`].subTotal !== "NaN"
                          ? row[`Row${index + 1}`].subTotal
                          : ""
                      }
                      // onChange={(e) => handleInputChange(e, index, "Col5")}
                    />
                  </td>
                </tr>
              );
            })}
            <tr scope="row">
              <td rowSpan={3} colSpan={3}>
                <Table style={{ backgroundColor: "blue" }} bordered>
                  <thead>
                    <tr>
                      <th>Tax</th>
                      <th>%</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>SGST</td>
                      <td> 6 %</td>
                      <td>{totalSGST}</td>
                    </tr>
                    <tr>
                      <td>CGST</td>
                      <td> 6 %</td>
                      <td>{totalCGST}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <td>12%</td>
                      <td>{parseFloat(totalSGST) + parseFloat(totalCGST)}</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td
                colSpan={3}
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  paddingTop: "15px",
                }}
              >
                Total
              </td>
              <td>
                {" "}
                <Input readOnly value={total !== NaN ? total : ""} />
              </td>
            </tr>
            <tr scope="row">
              <td
                colSpan={3}
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  paddingTop: "15px",
                }}
              >
                Discount
              </td>
              <td>
                {" "}
                <Input
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  value={discount !== NaN ? discount : ""}
                />
              </td>
            </tr>
            <tr scope="row">
              <td
                colSpan={3}
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  paddingTop: "15px",
                }}
              >
                Net Payable
              </td>
              <td>{netPayable !== NaN ? netPayable : ""}</td>
            </tr>
            <tr scope="row">
              <td
                colSpan={6}
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  paddingTop: "15px",
                }}
              >
                Recieved Amount
              </td>
              <td>
                <Input
                  onChange={(e) => {
                    if (isNaN(e.target.value)) {
                      alert("Please enter Numeric");
                      return;
                    }
                    setRecievedAmt(e.target.value);
                  }}
                  value={recievedAmt !== NaN ? recievedAmt : ""}
                />
              </td>
            </tr>
            <tr scope="row">
              <td
                colSpan={6}
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  paddingTop: "15px",
                }}
              >
                Balance Amount
              </td>
              <td>{balanceAmount}</td>
            </tr>
            <tr>
              <td colSpan={7}>
                <b>Total Amount In Words: </b> {numberToWords(netPayable)}{" "}
                Rupees
              </td>
            </tr>
          </tbody>
        </Table>
        <Row>
          <Col style={{ maxWidth: "150px" }}>
            <Label>Payment Mode</Label>
          </Col>
          <Col md="3">
            <Input
              id="paymode"
              type="text"
              name="input1"
              value={payment_mode}
              onChange={(e) => setPayment_mode(e.target.value)}
            />
          </Col>
        </Row>
        This is a computer-generated invoice. No signature is required.
      </div>
      <Row>
        <Col className="ps-5"></Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={2}></Col>
      </Row>

      <Row className="d-flex justify-content-between ps-5 pe-5">
        <Col md={2}>
          <Button onClick={handlePrint} color="primary">
            Print
          </Button>
        </Col>
        <Col md={2}>
          {saving && <Spinner color="primary" type="grow"></Spinner>}
          <Button onClick={submitHandler} color="primary">
            Save Invoice
          </Button>
        </Col>
      </Row>

      <Row className="p-5">
        <Col md="1"></Col>
      </Row>
      {
        //print component
      }
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          style={{
            fontSize: "11px",
            paddingLeft: "15px",
            marginRight: "15px",
          }}
        >
          <div className="d-flex justify-content-start text-md pt-2">
            <div style={{ width: "40%" }} className="d-flex flex-column">
              <div style={{ height: "80px" }}>
                {<img src={ci} alt="image" style={{ height: "100px" }} />}
              </div>

              <div>
                <div style={{ paddingLeft: "70px", fontSize: "12px" }}>
                  <b>GSTIN:</b>
                  {addressPrint.length > 0 && addressPrint[0].GSTIN}
                  <br />
                  <b>Mobile:</b>9916975371
                </div>{" "}
              </div>
            </div>

            {addressPrint.length > 0 && (
              <div
                className="pt-4 pe-2"
                style={{ fontSize: "12px", width: "300px" }}
              >
                <span style={{ color: "#C70039", fontSize: "14px" }}>
                  <b>{addressPrint[0].address.first_line}</b>
                </span>{" "}
                <br />
                <span style={{ fontSize: "11px" }}>
                  {addressPrint[0].address.second_line}
                </span>
                <br />
                <span style={{ fontSize: "11px" }}>
                  {addressPrint[0].address.third_line},{" "}
                  {addressPrint[0].address.fourth_line}
                </span>
              </div>
            )}
            <div className="pt-4" style={{ fontSize: "10px" }}>
              <b>Tax Invoice</b>
              <div
                style={{
                  display: "inline-block",
                  border: "solid 1px grey",
                  color: "grey",
                  minWidth: 0,
                }}
              >
                ORIGINAL FOR RECIPIENT
              </div>
              <br />
              Invoice No:{invoice_id}
              <br />
              Date : {formatDate(date)}
            </div>
          </div>
          <Row className="d-flex justify-content-center pt-2">
            <Col md="2" style={{ fontSize: "12px", textAlign: "center" }}>
              Receipt {}
            </Col>
          </Row>
          <Table bordered style={{ fontSize: "11px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "5%",
                    textAlign: "center",
                  }}
                >
                  SL.No.
                </th>
                <th
                  style={{
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  Items/Services
                </th>
                <th
                  style={{
                    width: "10%",
                  }}
                >
                  HSN/SAC/Batch/Expiry
                </th>
                <th
                  style={{
                    width: "10%",
                  }}
                >
                  Qty
                </th>
                <th
                  style={{
                    width: "10%",
                  }}
                >
                  {" "}
                  Rate
                </th>
                <th
                  style={{
                    width: "10%",
                  }}
                >
                  Tax
                </th>

                <th
                  style={{
                    width: "10%",
                  }}
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {phmcyInvoiceSheet.map((row, index) => {
                return (
                  <tr
                    key={index}
                    style={{ borderTop: "none", borderBottom: "none" }}
                  >
                    <td>{row[`Row${index + 1}`].item && index + 1}</td>
                    <td>{row[`Row${index + 1}`].item}</td>
                    <td>{row[`Row${index + 1}`].HSN_SAC}</td>
                    <td>{row[`Row${index + 1}`].qty}</td>
                    <td>
                      {row[`Row${index + 1}`].unitPrice
                        ? row[`Row${index + 1}`].unitPrice
                        : ""}
                    </td>
                    <td>
                      {
                        row[`Row${index + 1}`].tax
                          ? row[`Row${index + 1}`].tax
                          : ""
                        //  row[`Row${index + 1}`].Col3
                        //    ? (
                        //        (row[`Row${index + 1}`].Col3 * 12) /
                        //        100
                        //      ).toFixed(2)
                        //    : ""
                      }
                    </td>
                    <td>
                      {row[`Row${index + 1}`].subTotal
                        ? row[`Row${index + 1}`].subTotal
                        : ""}
                    </td>
                  </tr>
                );
              })}
              <tr scope="row">
                <td rowSpan={3} colSpan={3}>
                  <Table style={{ backgroundColor: "blue" }} bordered>
                    <thead>
                      <tr>
                        <th>Tax</th>
                        <th>%</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>SGST</td>
                        <td> 6 %</td>
                        <td>{totalSGST}</td>
                      </tr>
                      <tr>
                        <td>CGST</td>
                        <td> 6 %</td>
                        <td>{totalCGST}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>12%</td>
                        <td>{parseFloat(totalSGST) + parseFloat(totalCGST)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    fontWeight: "bold",
                    paddingTop: "15px",
                  }}
                >
                  Total
                </td>
                <td> {total !== NaN ? total : ""}</td>
              </tr>
              <tr scope="row">
                <td
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    fontWeight: "bold",
                    paddingTop: "15px",
                  }}
                >
                  Discount
                </td>
                <td> {discount !== NaN ? discount : ""}</td>
              </tr>
              <tr scope="row">
                <td
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    fontWeight: "bold",
                    paddingTop: "15px",
                  }}
                >
                  Net Payable
                </td>
                <td>{netPayable !== NaN ? netPayable : ""}</td>
              </tr>
              <tr scope="row">
                <td
                  colSpan={6}
                  style={{
                    textAlign: "end",
                    fontWeight: "bold",
                    paddingTop: "15px",
                  }}
                >
                  Recieved Amount
                </td>
                <td>{recievedAmt !== NaN ? recievedAmt : ""}</td>
              </tr>
              <tr scope="row">
                <td
                  colSpan={6}
                  style={{
                    textAlign: "end",
                    fontWeight: "bold",
                    paddingTop: "15px",
                  }}
                >
                  Balance Amount
                </td>
                <td>{balanceAmount}</td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <b>Total Amount In Words: </b> {numberToWords(netPayable)}{" "}
                  Rupees
                </td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col style={{ maxWidth: "150px" }}>
              <Label>Payment Mode</Label>
            </Col>
            <Col md="3">{payment_mode}</Col>
          </Row>
          This is a computer-generated invoice. No signature is required.
        </div>
        <Row>
          <Col className="ps-5"></Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Col md={2}></Col>
        </Row>
      </div>
    </Container>
  );
};

export default Pharmacy;
