import { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

const DateSelection = ({
  selectedDate,
  setSelectedDate,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  dateSubmitHandler,
}) => {
  const [filterType, setFilterType] = useState("date");
  // const [selectedDate, setSelectedDate] = useState("");
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");

  // Validate date inputs
  const validateDate = (date) => {
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
    return pattern.test(date);
  };

  // show date in format
  const formatDate = (dte) => {
    if (validateDate(dte)) {
      const fdate = new Date(dte);

      const ISTOffset = 330; // IST offset in minutes
      const ISTTime = new Date(fdate.getTime() + ISTOffset * 60000); // IST time
      const formattedDate = ISTTime.toISOString().split("T")[0];

      return formattedDate;
    }
  };
  const validate = () => {
    let isValid = true;

    if (!validateDate(fromDate) && !validateDate(selectedDate)) {
      setFromDateError("Please enter a valid From Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setFromDateError("");
      setDateError("");
    }

    if (!validateDate(toDate) && !validateDate(selectedDate)) {
      setToDateError("Please enter a valid To Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setToDateError("");
    }
    return isValid;
  };

  const clearError = () => {
    setDateError("");
    setFromDateError("");
    setToDateError("");
  };

  const filterHandler = () => {
    //setTotalFilteredInvoices("");
    dateSubmitHandler();
    // setFilteredInvoices("");
    if (validate()) {
      let newSelectedDate;
      let date;
      let fromDateWithoutHours;
      let toDateWithoutHours;
      if (selectedDate) {
        newSelectedDate = new Date(selectedDate);
        date = new Date(newSelectedDate.setHours(0, 0, 0, 0)).toISOString();
      }

      if (fromDate) {
        newSelectedDate = new Date(fromDate);
        fromDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }
      if (toDate) {
        newSelectedDate = new Date(toDate);
        toDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }
    }
  };

  return (
    <div className="d-flex">
      {" "}
      <FormGroup className="me-3 ps-2">
        <Label for="filterType">Filter Type:</Label>
        <Input
          type="select"
          name="filterType"
          id="filterType"
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
        >
          {
            // <option value="">Select</option>
            // <option value="yearly">Yearly</option>
            //  <option value="monthly">Monthly</option>
          }
          <option value="date">Date</option>
        </Input>
      </FormGroup>
      {filterType === "date" && (
        <FormGroup>
          <Label for="selectedDate">Select Date:</Label>
          <Input
            type="date"
            name="selectedDate"
            id="selectedDate"
            value={selectedDate && formatDate(selectedDate)}
            onChange={(e) => {
              clearError();
              setSelectedDate(e.target.value);
              setFromDate("");
              setToDate("");
            }}
            invalid={dateError !== ""}
          />
        </FormGroup>
      )}
      {filterType !== "date" && (
        <>
          <FormGroup>
            <Label for="fromDate">From Date:</Label>
            <Input
              type="date"
              name="fromDate"
              id="fromDate"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                clearError();
              }}
              invalid={fromDateError !== ""}
            />
          </FormGroup>
          <FormGroup>
            <Label for="toDate">To Date:</Label>
            <Input
              type="date"
              name="toDate"
              id="toDate"
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
                clearError();
              }}
              invalid={toDateError !== ""}
            />
          </FormGroup>
        </>
      )}
      <button
        outline
        size="sm"
        color="primary"
        onClick={filterHandler}
        className=" ms-3"
        style={{
          width: "auto",
          height: "40px",
          borderRadius: "10px",
          marginTop: "30px",
        }}
      >
        Search
      </button>
    </div>
  );
};

export default DateSelection;
