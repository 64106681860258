export const distributeQuantities = (items, totalQty) => {
  items.sort((a, b) => parseFloat(a.quantity) - parseFloat(b.quantity));

  let remainingQty = totalQty;
  return items.map((item) => {
    if (remainingQty <= 0) return { ...item, qty: 0 };

    const itemStockQty = parseFloat(item.quantity);
    const allocatedQty = Math.min(itemStockQty, remainingQty);
    remainingQty -= allocatedQty;

    return { ...item, qty: allocatedQty };
  });
};
// prepare sheet for finding respective product Ids and quantity
export const prepareSheet = (invoiceSheet, itemsData) => {
  let updatedSheet = [];
  let newRowIndex = 1;

  invoiceSheet.forEach((sheetRow, index) => {
    const rowKey = `Row${index + 1}`;
    const row = sheetRow[rowKey];
    const { item, qty, mrp } = row;

    if (!item) return;

    const filteredItems = itemsData.filter(
      (data) => data.item === item
      //&& data.mrp === mrp
    );

    const totalStockQty = filteredItems.reduce(
      (acc, item) => acc + parseFloat(item.quantity),
      0
    );
    const totalQty = parseFloat(qty);
    // console.log(filteredItems, totalQty, totalStockQty);

    if (totalStockQty < totalQty) {
      // If the total stock quantity is less than the required quantity, skip this row and keep it unchanged
      updatedSheet.push({
        [rowKey]: row,
      });
      newRowIndex++;
    } else {
      const distributedItems = distributeQuantities(filteredItems, totalQty);

      distributedItems.forEach((distributedItem) => {
        const newRowKey = `Row${newRowIndex}`;
        updatedSheet.push({
          [newRowKey]: {
            ...row,
            qty: distributedItem.qty,
            productId: distributedItem.productId,
            stockQty: distributedItem.quantity,
          },
        });
        newRowIndex++;
      });
    }
  });

  // Filter out empty rows from the final sheet
  updatedSheet = updatedSheet.filter((sheetRow) => {
    const key = Object.keys(sheetRow)[0];
    return sheetRow[key].item && sheetRow[key].qty;
  });

  // Reorder the rows to ensure they follow Row1, Row2, etc.
  const reorderedSheet = updatedSheet.reduce((acc, row, index) => {
    const newRowKey = `Row${index + 1}`;
    const oldRowKey = Object.keys(row)[0];
    acc.push({ [newRowKey]: row[oldRowKey] });
    return acc;
  }, []);
  // console.log(reorderedSheet);
  return reorderedSheet;
};
