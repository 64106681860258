import { Col, Container, Row } from "reactstrap";
import "./style.css";
import { Bold } from "react-feather";

const Home = () => {
  return (
    <Container
      className="home-container mt-5 pt-3 pb-3"
      style={{ border: "solid white" }}
    >
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col className="text-center">
          <div className="main-circle-container">
            <div className="main-circle">
              <div className="content">
                <h6>
                  <strong>PAUPET SOFT</strong>
                </h6>
                <p>Precisely Integrated</p>
              </div>
            </div>
            <div className="small-circles">
              <div className="small-circle p-2">HR Software</div>
              <div className="small-circle">Online Store </div>
              <div className="small-circle">Inventory</div>
              <div className="small-circle">Home Care</div>
              <div className="small-circle">Medical</div>
              <div className="small-circle">Lab Management</div>
              <div className="small-circle">Fintech</div>
              <div className="small-circle">Learning & Development</div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
