import { useEffect, useState, React, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Spinner,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import ci from "../../../../images/cutis_new.jpeg";
import "../../CRM/styles/style.css";
import ReactToPrint from "react-to-print";
import axios from "axios";
import {
  editInvoice,
  getProcedures,
  getbranchData,
  searchCustomers,
  searchInvoices,
} from "../../../../Data/Customer/api";
import PrintBill from "./PrintBill";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { getInventoryItems } from "../../../../redux/inventory";
import PurchaseEntry from "../../CRM-Calicut/Pharmacy/Purchase/Bills/PurchaseEntry";
import Return from "../../CRM-Calicut/Pharmacy/Purchase/Bills/Return";
import { Edit, Plus, Search } from "react-feather";
import PaymentModes from "./PaymentModes";
import { distributeQuantities, prepareSheet } from "./utils";

const BillEntry = (args) => {
  const auth = useSelector((state) => state.authentication);
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData } = itemsList;
  const dispatch = useDispatch();
  const { userData, branchData } = auth;
  let savedToggle = false;
  const token = sessionStorage.getItem("token")
    ? JSON.parse(sessionStorage.getItem("token"))
    : "";
  const [username] = useState(
    sessionStorage.getItem("username")
      ? JSON.parse(sessionStorage.getItem("username"))
      : ""
  );
  const componentRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState("");

  const [date, setDate] = useState(new Date());
  const [mrn, setMrn] = useState(data && data.otData.mrn);
  const dateNew = new Date();
  const [datewithoutHours, setDatewithoutHours] = useState(
    new Date(dateNew.setHours(0, 0, 0, 0)).toISOString()
  );
  const time = date.getTime();
  const branch = userData.branch;

  const [invoice_id, setInvoice_id] = useState("");
  const [rtnInvoice_id, setRtnInvoice_id] = useState("");
  const status = "";
  const [remarks, setRemarks] = useState(data && data.otData.remarks);
  const [buttonHide, setButtonHide] = useState({ display: "normal" });
  const [printVisible, setPrintVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState("");
  // const [updatedSheetStock, setUpdatedSheetStock] = useState("");
  const formatDate = (dte) => {
    //22-04-2024 updated checked ok
    const now = new Date(dte);
    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds (5 hours 30 minutes)
    const istTime = new Date(now.getTime() + istOffset);

    const formattedDate = istTime.toISOString().split("T")[0];

    return formattedDate;
  };

  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [selectedRtnDate, setSelectedRtnDate] = useState(
    formatDate(new Date())
  );
  const [address, setAddress] = useState("");
  const [addressPrint, setAddressPrint] = useState("");
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [netAmountPay, setNetAmountPay] = useState("");
  const total_amount = total;

  const [procedures, setProcedures] = useState("");
  const [GSTIN, setGSTIN] = useState("");
  const [discount, setDiscount] = useState("");
  const [receivedAmt, setReceivedAmt] = useState("");
  const total_amount_paid = receivedAmt;
  const [balanceAmount, setBalanceAmount] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [savedData, setSavedData] = useState("");
  const [modal, setModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleLoadingModal = () => setLoadingModal(!loadingModal);
  const [patientListModal, setPatientListModal] = useState(false);
  const togglepatientListModal = () => setPatientListModal(!patientListModal);
  const [selectedCM, setSelectedCM] = useState("");
  const [payments, setPayments] = useState([{ mode: "Cash", amount: "" }]);
  const [adjustedAmount, setAdjustedAmount] = useState("");
  const [refundIssued, setRefundIssued] = useState("");
  const [returnProcessedAt, setReturnProcessedAt] = useState("");
  // const [payment_mode, setPayment_mode] = useState(payments);
  // added for updatinge dit bill stock qty
  const [invoiceSheetEditBill, setInvoiceSheetEditBill] = useState();

  const [invoiceSheet, setInvoiceSheet] = useState([
    {
      Row1: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 0, sgst: 0 },
    },
    {
      Row2: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 0, sgst: 0 },
    },
    {
      Row3: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 0, sgst: 0 },
    },
    {
      Row4: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 0, sgst: 0 },
    },
    {
      Row5: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 0, sgst: 0 },
    },
    {
      Row6: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 0, sgst: 0 },
    },
  ]);
  const addRow = () => {
    const lastRowNumber = Object.keys(invoiceSheet).length;
    const newRowNumber = lastRowNumber + 1;

    setInvoiceSheet([
      ...invoiceSheet,
      {
        [`Row${newRowNumber}`]: {
          item: "",
          qty: "",
          rate: 0,
          tax: 0,
          amount: 0,
          cgst: 0,
          sgst: 0,
        },
      },
    ]);
  };

  const [totalQTY, setTotalQTY] = useState("");
  const [totalTax, setTotalTax] = useState("");
  const [taxableData, setTaxableData] = useState("");
  const invoice_sheet = invoiceSheet;
  const [formData, setFormData] = useState({
    doctorName: data ? data.otData.doctorName : "",
    patientName: data ? data.otData.fname : "",
  });

  const clearForm = () => {
    setReceivedAmt("");
    setInvoice_id("");
    setInvoiceSheet([
      {
        Row1: {
          item: "",
          qty: "",
          rate: 0,
          tax: 0,
          amount: 0,
          cgst: 9,
          sgst: 9,
        },
      },
      {
        Row2: {
          item: "",
          qty: "",
          rate: 0,
          tax: 0,
          amount: 0,
          cgst: 9,
          sgst: 9,
        },
      },
      {
        Row3: {
          item: "",
          qty: "",
          rate: 0,
          tax: 0,
          amount: 0,
          cgst: 9,
          sgst: 9,
        },
      },
      {
        Row4: {
          item: "",
          qty: "",
          rate: 0,
          tax: 0,
          amount: 0,
          cgst: 9,
          sgst: 9,
        },
      },
      {
        Row5: {
          item: "",
          qty: "",
          rate: 0,
          tax: 0,
          amount: 0,
          cgst: 9,
          sgst: 9,
        },
      },
      {
        Row6: {
          item: "",
          qty: "",
          rate: 0,
          tax: 0,
          amount: 0,
          cgst: 9,
          sgst: 9,
        },
      },
    ]);
  };

  const validateDate = (dateString) => {
    if (typeof dateString !== "string") {
      return false;
    }
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const match = dateString.match(regex);

    if (!match) {
      return false; // Date string does not match the expected format
    }

    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // Zero-based month
    const day = parseInt(match[3], 10);

    // Check if the date is valid
    const date = new Date(year, month, day);
    if (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    ) {
      return true; // Valid date
    }

    return false; // Invalid date
  };

  useEffect(() => {
    if (validateDate(selectedDate)) {
      const nDate = new Date(selectedDate);
      const withoutHours = new Date(nDate.setHours(0, 0, 0, 0)).toISOString();
      setDatewithoutHours(withoutHours);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (validateDate(selectedRtnDate)) {
      //   const nDate = new Date(selectedRtnDate);  trying with raw date
      //  const withoutHours = new Date(nDate.setHours(0, 0, 0, 0)).toISOString();
      setReturnProcessedAt(selectedRtnDate);
    }
  }, [selectedRtnDate]);

  const validateInvoiceSheet = () => {
    for (let i = 0; i < invoiceSheet.length; i++) {
      const row = invoiceSheet[i][`Row${i + 1}`];
      if (row.item && row.productId) {
        if (!row.item || !row.qty || !row.rate || !row.amount) {
          // Validation failed
          return false;
        }
      } else if (row.item) {
        if (!row.rate || !row.amount) {
          // Validation failed
          return false;
        }
      } else if (!row.item) {
        return false;
      }
      // Validation passed
      return true;
    }
    return true;
  };

  useEffect(() => {
    if (userData.token) {
      dispatch(
        getInventoryItems({ token: userData.token, branch: userData.branch })
      );
    }
  }, [userData.token]);

  useEffect(() => {
    if (branchData.length > 0) {
      const { first_line, second_line, third_line, fourth_line } =
        branchData[0].address;
      const { GSTIN } = branchData[0];
      setGSTIN(GSTIN);

      setAddressPrint({
        first_line,
        second_line,
        third_line,
        fourth_line,
      });
      setAddress(() => {
        return (
          <div className="ps-3">
            <h6>{first_line}</h6>
            <p className="mb-0">
              {second_line}, {third_line}, {fourth_line}
            </p>
          </div>
        );
      });
    }
  }, [branchData]);

  useEffect(() => {
    //   if (data && data.invoiceSheetData && userData.rights === "Accounts") {
    if (data && data.invoiceSheetData && !searchResult) {
      //  readOnly={data !== "" && !editMode}

      setEditMode(false);

      setInvoiceSheet(data.invoiceSheetData.invoice_sheet);
      // setNetAmountPay();
      setDiscount(data.invoiceSheetData.discount);
      setReceivedAmt(data.invoiceSheetData.total_amount_paid);
      setInvoice_id(data.invoiceSheetData.invoice_id);
      setDate(new Date(data.invoiceSheetData.invoice_date));
      if (Array.isArray(data.invoiceSheetData.payment_mode)) {
        setPayments(data.invoiceSheetData.payment_mode);
      } else if (typeof data.invoiceSheetData.payment_mode === "string") {
        setPayments([{ mode: data.invoiceSheetData.payment_mode, amount: "" }]);
      }

      setSelectedDate(() => {
        //   const dateV = new Date(data.invoiceSheetData.invoice_date);
        return formatDate(data.invoiceSheetData.invoice_date);
      });

      // setSelectedDate(data.invoiceSheetData.invoice_sheet.invoice_date);
      setEditData(data.invoiceSheetData);
    }
    if (
      data &&
      data.invoiceSheetData &&
      !searchResult &&
      data?.mode === "Return Mode"
    ) {
      setRtnInvoice_id(data.invoiceSheetData.rt_invoice_id);
    }
  }, [data]);

  useEffect(() => {
    setBalanceAmount(() => {
      const balance =
        parseFloat(netAmountPay.trim()) - parseFloat(receivedAmt.trim());
      const final = balance ? balance : 0;

      return final === 0 ? 0.0 : final.toFixed(2);
    });
  }, [receivedAmt, netAmountPay]);

  useEffect(() => {
    if (tax || total || discount) {
      // const taxTemp = ((Number(total)) / 100).toFixed(2);
      // const rowTotal = (parseFloat(taxTemp) + parseFloat(total)).toFixed(2);
      const netPay = (parseFloat(total) - parseFloat(discount)).toFixed(2);

      setNetAmountPay(() =>
        !isNaN(netPay) ? netPay : parseFloat(total).toFixed(2)
      );
    }
  }, [tax, total, discount]);

  useEffect(() => {
    if (branch && token) {
      getProcedures(token, branch, navigate).then((res) => {
        setProcedures(res.data);
      });
    }
    const fetchBranchData = async () => {
      if (userData.rights === "Accounts" && token) {
        dispatch(getInventoryItems({ token, branch }));
      }
      if (userData.branch) {
        try {
          const res = await getbranchData(
            userData.token,
            userData.branch,
            navigate
          );

          if (res.length) {
            const { first_line, second_line, third_line, fourth_line } =
              res[0].address;
            const { GSTIN } = res[0];
            setGSTIN(GSTIN);

            setAddressPrint({
              first_line,
              second_line,
              third_line,
              fourth_line,
            });
            setAddress(() => {
              return (
                <div className="ps-3">
                  <h6>{first_line}</h6>
                  <p className="mb-0">
                    {second_line}, {third_line}, {fourth_line}
                  </p>
                </div>
              );
            });
            sessionStorage.setItem("address", JSON.stringify(res));
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    };

    fetchBranchData();
  }, [branch, token]);

  //useEffect(() => {}, []);

  useEffect(() => {
    if (printVisible) {
      setButtonHide({ display: "none" });
    }

    // setButtonHide({ display: "none" });
    // return () => setPrintVisible(false);
  }, [printVisible]);

  const updateInventory = (productId, qty) => {
    const postDataInventory = {
      quantity: qty,
    };
    axios
      .post(
        `${process.env.REACT_APP_CRI_API}update-inventory-qty`,
        postDataInventory,
        {
          params: {
            branch: branch,
            productId: productId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("Inventory Updated");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateSheet = (sheet) => {
    //prepare sheet will distribute the qty as per stockQty and productIds for update stock
    const updatedInvoiceSheet = prepareSheet(sheet, itemsData);
    console.log(updatedInvoiceSheet);
    //setUpdatedSheetStock(updatedInvoiceSheet);
    stockHandler(updatedInvoiceSheet);
  };

  const stockHandler = (sheet) => {
    const consolidatedQuantities = {};

    // Consolidate quantities for the same productId
    sheet.forEach((row, rowIndex) => {
      const { productId, qty = 1, stockQty } = row[`Row${rowIndex + 1}`];
      if (productId) {
        consolidatedQuantities[productId] = {
          totalQty:
            Number(consolidatedQuantities[productId]?.totalQty || 0) +
            Number(qty),
          stockQty: stockQty,
        };
      }
    });

    // Prepare promises for updating inventory
    const promises = Object.entries(consolidatedQuantities).map(
      ([productId, { totalQty, stockQty }]) => {
        let updtQty = "";
        if (data?.mode === "Return Mode") {
          updtQty = Number(stockQty) + Number(totalQty);
        } else {
          updtQty = Number(stockQty) - Number(totalQty);
        }

        return updateInventory(productId, updtQty);
      }
    );

    // Execute promises and handle results
    Promise.all(promises)
      .then(() => {
        // Perform any other actions (e.g., submit the invoice)
        dispatch(
          getInventoryItems({ token: userData.token, branch: userData.branch })
        );
      })
      .catch((error) => {
        console.error("Error updating inventory:", error);
      });
  };

  const calculateTotal = () => {
    const amountValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`].amount)
      .filter((i) => parseFloat(i));

    const qtyValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`].qty)
      .filter((i) => parseFloat(i));

    const taxValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`].tax)
      .filter((i) => parseFloat(i));

    const taxableList = invoiceSheet
      .filter((row, index) => row[`Row${index + 1}`].tax)
      .map((row) => {
        const key = Object.keys(row)[0];

        return row[key];
        // return parseFloat(row[key].rate) * parseFloat(row[key].qty);
      });
    setTaxableData(taxableList);
    const totalTempTax = taxValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    setTotalTax(totalTempTax.toFixed(2));

    const totalTempQty = qtyValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    setTotalQTY(totalTempQty);

    //const values = Object.values(inputValues).map((value) => parseFloat(value));
    const totalTempAmt = amountValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    setTotal(parseFloat(totalTempAmt).toFixed(2));
    //  return total;
  };

  useEffect(() => {
    if (invoiceSheet) calculateTotal();
  }, [data, invoiceSheet]);

  const handleInputProcedure = (e, rowIndex, colName, row) => {
    const { name, value } = e.target;

    const trimmedValue = value.trim();
    const selectedOption = e.target.list.querySelector(
      `option[value="${trimmedValue}"]`
    );
    let cost = selectedOption ? selectedOption.getAttribute("data-cost") : null;

    const taxInput = selectedOption
      ? selectedOption.getAttribute("data-tax")
      : null;

    let [gst, cess] = [0, 0]; // Default values if split doesn't work

    if (taxInput && taxInput.includes("_")) {
      [gst, cess] = taxInput.split("_").map(parseFloat);
    } else {
      // Handle the case when "_" is not present
      gst = taxInput;
    }

    const taxValue = parseFloat(gst);
    //const taxInputper = (taxValue / totalRate) * 100;
    const cgst = (taxValue / 2).toFixed(2);
    const sgst = (taxValue / 2).toFixed(2);
    const product = selectedOption
      ? selectedOption.getAttribute("data-item")
      : null;

    const productId = selectedOption
      ? selectedOption.getAttribute("data-productid")
      : null;

    const stockQty = selectedOption
      ? selectedOption.getAttribute("data-qty")
      : null;

    const word = "consult";
    let taxToggle = true;
    //  if (value.toLowerCase().includes(word.toLowerCase())) {
    //    taxToggle = false;
    //  } else {
    //    taxToggle = true;
    //  }
    //      value={`${item.item} (${item.productId}) ${item.quantity}`}

    // setTotal(cost);

    // Convert null or empty string to 0
    //  const numericValue = value === null || value === "" ? 0 : parseFloat(value);
    //  setInputValues((prevValues) => ({
    //    ...prevValues,
    //    [name]: numericValue,
    //  }));

    const taxCal = (
      ((Number(cost) / (parseFloat(gst) / 100 + 1)) * parseFloat(gst)) /
      100
    ).toFixed(2);
    let taxTemp = taxToggle ? taxCal : 0;

    cost = (parseFloat(cost) - parseFloat(taxTemp)).toFixed(2);

    const rowTotal = (parseFloat(cost) + parseFloat(taxTemp)).toFixed(2);
    taxTemp = parseFloat(taxTemp) > 0 ? taxTemp : "";

    //setTaxOption([{ value: taxTemp, label: `${taxTemp} (18%)` }]);

    setInvoiceSheet((prevInvoiceSheet) => {
      return prevInvoiceSheet.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [`Row${rowIndex + 1}`]: {
              ...row[`Row${rowIndex + 1}`],
              [colName]: product,
              cgst,
              sgst,
              gst,
              rate: isNaN(cost) ? "" : cost,
              qty: product || value ? 1 : "",
              tax: value ? taxTemp : "",
              amount: value ? Math.round(rowTotal) : "",
              productId,
              stockQty,
            },
          };
        }
        return row;
      });
    });
  };

  const handleInputChange = (e, rowIndex, colName, row) => {
    if (!row[`Row${rowIndex + 1}`].item) {
      alert("Please select item");
      return;
    }
    const { name, value } = e.target;
    const num = validator.isNumeric(value, {
      no_symbols: false,
    });
    const decimal = validator.isFloat(value, {
      no_symbols: false,
    });

    if (colName === "tax") {
      const qty = row[`Row${rowIndex + 1}`].qty || 1;

      // const percent = value === null || value === "" ? 0 : parseFloat(value);
      const taxValue = parseFloat(value);

      // consider sgst also same

      const rate = parseFloat(row[`Row${rowIndex + 1}`].rate);
      const totalRate = rate * Number(qty);
      // for re calc for total rate percent or tax per goes high
      const taxInputper = (taxValue / totalRate) * 100;
      const cgst = (taxInputper / 2).toFixed(2);
      const sgst = (taxInputper / 2).toFixed(2);

      const rowTotal = parseFloat(totalRate).toFixed(2);

      setInvoiceSheet((prevInvoiceSheet) => {
        return prevInvoiceSheet.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              [`Row${rowIndex + 1}`]: {
                ...row[`Row${rowIndex + 1}`],
                [colName]: value,
                cgst,
                sgst,
                amount: !isNaN(rowTotal)
                  ? Math.round(rowTotal)
                  : parseFloat(row[`Row${rowIndex + 1}`].rate) *
                    parseFloat(qty),
              },
            };
          }
          return row;
        });
      });
      //  setInputValues((prevValues) => ({
      //    ...prevValues,
      //    [name]: value,
      //  }));
      return;
    }
    if (colName === "rate") {
      // for excluding tax calc for consultation

      const procedureTemp = row[`Row${rowIndex + 1}`].item;
      const word = "consult";
      let taxToggle = true;
      // as discussed with Raees
      //   if (procedureTemp.toLowerCase().includes(word.toLowerCase())) {
      //     taxToggle = false;
      //   } else {
      //     taxToggle = true;
      //   }
      //cal tax then total
      if (row[`Row${rowIndex + 1}`].qty) {
        const qty = row[`Row${rowIndex + 1}`].qty;
        const gst = row[`Row${rowIndex + 1}`].gst;
        const taxCal = ((parseFloat(value) * gst) / 100).toFixed(2);
        const taxTemp = taxToggle ? taxCal : 0;
        const taxtotal = (parseFloat(taxTemp) * parseFloat(qty)).toFixed(2);

        //  const itemsTotal = parseFloat(value) * parseFloat(qty);

        //  const rowTotal = parseFloat(itemsTotal).toFixed(2);
        const rowTotal = (
          parseFloat(value) * parseFloat(qty) +
          parseFloat(taxtotal)
        ).toFixed(2);

        // print bill cgst sgst
        setInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [`Row${rowIndex + 1}`]: {
                  ...row[`Row${rowIndex + 1}`],
                  [colName]: value,
                  tax: taxToggle ? taxtotal : "",
                  amount: !isNaN(rowTotal) ? Math.round(rowTotal) : "",
                },
              };
            }
            return row;
          });
        });
        return;
      }
      const taxTemp = (((Number(value) / 1.18) * 18) / 100).toFixed(2);

      const rowTotal = (parseFloat(value) * 1).toFixed(2);

      setInvoiceSheet((prevInvoiceSheet) => {
        return prevInvoiceSheet.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              [`Row${rowIndex + 1}`]: {
                ...row[`Row${rowIndex + 1}`],
                [colName]: value,
                tax: taxTemp,
                amount: rowTotal,
              },
            };
          }
          return row;
        });
      });
    }
    if (colName === "amount") {
      if (
        row[`Row${rowIndex + 1}`].productId ||
        row[`Row${rowIndex + 1}`].item
      ) {
        const quantity = row[`Row${rowIndex + 1}`].qty;
        const gst = row[`Row${rowIndex + 1}`].gst;

        function calculateRate(amount, quantity, taxPercentage) {
          const totalAmountWithoutTax = amount / (1 + taxPercentage / 100);
          return (totalAmountWithoutTax / quantity).toFixed(2);
        }

        function calculateTaxAmount(rate, quantity, taxPercentage) {
          const amountWithoutTax = rate * quantity;
          const taxAmount = amountWithoutTax * (taxPercentage / 100);
          return taxAmount.toFixed(2);
        }

        let newRate = calculateRate(value, quantity, gst);

        let newTaxAmount = calculateTaxAmount(newRate, quantity, gst);

        let taxToggle = true;
        // disabled as per Raees
        //  if (row[`Row${rowIndex + 1}`].rate) {
        //    const word = "consult";
        //
        //    if (
        //      row[`Row${rowIndex + 1}`].item
        //        .toLowerCase()
        //        .includes(word.toLowerCase())
        //    ) {
        //      taxToggle = false;
        //    } else {
        //      taxToggle = true;
        //    }
        //  }
        setInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [`Row${rowIndex + 1}`]: {
                  ...row[`Row${rowIndex + 1}`],
                  [colName]: value,
                  rate: taxToggle ? newRate : value,
                  tax: taxToggle ? newTaxAmount : "",
                },
              };
            }
            return row;
          });
        });
      } else {
        setInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [`Row${rowIndex + 1}`]: {
                  ...row[`Row${rowIndex + 1}`],
                  [colName]: value,
                  rate: value,
                },
              };
            }
            return row;
          });
        });
      }
    }

    // Convert null or empty string to 0
  };
  // 3535 ron bus
  // km 65728 km 28-aug-23
  const qtyStockCheck = (item) => {
    const product = itemsData.find((prod) => prod.productId === item.productId);
    return product.quantity;
  };

  const qtyHandler = (e, index, colName, row) => {
    const value = e.target.value;

    const num = validator.isNumeric(value, {
      no_symbols: false,
    });
    const decimal = validator.isFloat(value, {
      no_symbols: false,
    });
    if (value !== "-" && (decimal || num || value === "")) {
      // const taxTemp = ((Number(e.target.value) * 12) / 100).toFixed(2);
      //   const taxTemp = parseFloat(row[`Row${index + 1}`].tax);
      // const taxTemp = ((Number(e.target.value) * 12) / 100).toFixed(2);
      handleInputChange(e, index, colName, row);

      if (row[`Row${index + 1}`].rate) {
        const word = "consult";
        let taxToggle = true;
        //  if (
        //    row[`Row${index + 1}`].item.toLowerCase().includes(word.toLowerCase())
        //  ) {
        //    taxToggle = false;
        //  } else {
        //    taxToggle = true;
        //  }
        const taxHandler = () => {
          //  const enteredTaxAmt = row[`Row${index + 1}`].tax;
          //  if (enteredTaxAmt && parseFloat(enteredTaxAmt) > 0) {
          //    console.log(enteredTaxAmt);
          //    return (
          //      parseFloat(row[`Row${index + 1}`].cgst) +
          //      parseFloat(row[`Row${index + 1}`].sgst)
          //    ).toFixed(2);
          //  }
          const gst = parseFloat(row[`Row${index + 1}`].gst);
          return (
            (parseFloat(row[`Row${index + 1}`].rate) * gst) /
            100
          ).toFixed(2);
        };
        const taxCal = taxHandler();
        //  console.log(taxCal);
        const taxTemp = taxToggle ? taxCal : 0;
        const taxtotal = (parseFloat(taxTemp) * parseFloat(value)).toFixed(2);

        const rowTotal = (
          parseFloat(row[`Row${index + 1}`].rate) * parseFloat(e.target.value) +
          parseFloat(taxtotal)
        ).toFixed(2);

        //console.log(rowTotal);
        // direct update
        //   row[`Row${index + 1}`].qty
        setInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, rindex) => {
            if (rindex === index) {
              return {
                ...row,
                [`Row${index + 1}`]: {
                  ...row[`Row${index + 1}`],
                  amount: !isNaN(rowTotal) ? Math.round(rowTotal) : "",
                  qty: value,
                  tax: taxtotal,
                },
              };
            }
            return row;
          });
        });
      } else {
        setInvoiceSheet((prevInvoiceSheet) => {
          return prevInvoiceSheet.map((row, rindex) => {
            if (rindex === index) {
              return {
                ...row,
                [`Row${index + 1}`]: {
                  ...row[`Row${index + 1}`],
                  qty: parseFloat(e.target.value),
                },
              };
            }
            return row;
          });
        });
      }
    } else {
      alert("Enter Numeric Value");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const postData = {
    mrn: mrn || formData.mrn,
    branch,
    invoice_date: datewithoutHours,
    time,
    total_amount,
    payment_mode: payments,
    total_amount_paid,
    balanceAmount,
    invoice_id,
    invoice_sheet,
    tax,
    status,
    remarks,
    createdBy: username,
    discount,
    doctorName: formData.doctorName,
    patientName: formData.patientName,
  };

  const formValid = () => {
    if (!invoice_id) {
      //added this , pls check testing
      alert("Invoice Id Error");
      return false;
    }
    if (!receivedAmt) {
      alert("Enter Received Amount");
      return false;
    }
    if (postData.mrn && branch && datewithoutHours && total_amount) {
      if (payments.length) {
        return true;
      } else {
        alert("Select Payment Mode");
        return false;
      }
    } else {
      alert("Invalid Form");
      return false;
    }
  };

  const editPostData = {
    mrn: mrn || formData.mrn,
    branch,
    invoice_date: datewithoutHours,
    time,
    total_amount,
    payment_mode: payments,
    total_amount_paid,
    balanceAmount,
    invoice_id,
    invoice_sheet,
    tax,
    status,
    remarks,
    createdBy: username,
    discount,
    doctorName: formData.doctorName,
    patientName: formData.patientName,
  };

  const postRtnData = {
    mrn: mrn || formData.mrn,
    branch,
    invoice_date: datewithoutHours,
    time,
    total_amount,
    payment_mode: payments,
    total_amount_paid,
    balanceAmount,
    invoice_id,
    invoice_sheet,
    tax,
    status,
    remarks,
    createdBy: username,
    discount,
    doctorName: formData.doctorName,
    patientName: formData.patientName,
    adjustedAmount,
    refundIssued,
    returnProcessedAt,
    rtn_invoice_id: rtnInvoice_id,
  };

  const returnHandler = () => {
    axios
      .post(`${process.env.REACT_APP_CRI_API}add-rtn-invoice`, postRtnData, {
        params: {
          branch: branch,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setSavedData(res.data);
          // already saved message alert
          savedToggle = true;
          handleUpdateSheet(invoiceSheet);

          alert("Save Successful");

          // toggleLoadingModal();
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          (err.response &&
            err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }

        return;
      });
  };

  const editHandler = () => {
    //toggleLoadingModal();
    const valid = formValid();
    if (!validateInvoiceSheet()) {
      alert("Inalid invoice entry");
      return;
    } else if (valid) {
      editInvoice(editData._id, editPostData, branch, token, navigate)
        .then((res) => {
          if (res) {
            filterNewItemsHandler(data.invoiceSheetData.invoice_sheet);
            alert("Edit Successful");

            //  toggleLoadingModal();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const submitHandler = () => {
    // toggleLoadingModal();

    const valid = formValid();
    if (savedToggle) {
      alert("Already Saved");
      return;
    }
    if (!validateInvoiceSheet()) {
      alert("Invalid invoice entry");
      return;
    } else if (valid) {
      if (savedData.invoice_id !== invoice_id) {
        axios
          .post(`${process.env.REACT_APP_CRI_API}add-invoice`, postData, {
            params: {
              branch: branch,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setSavedData(res.data);
              // already saved message alert
              savedToggle = true;
              handleUpdateSheet(invoiceSheet);

              alert("Save Successful");

              // toggleLoadingModal();
            }
          })
          .catch((err) => {
            console.log(err);
            if (
              (err.response &&
                err.response.data &&
                err.response.data.err.name === "TokenExpiredError") ||
              err.response.data.message === "Unauthorized"
            ) {
              navigate("/");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("srchData");
              sessionStorage.removeItem("prescriptionHistory");
              sessionStorage.removeItem("rights");
              navigate("/cri-crm");
              return err;
            }

            return;
          });
      } else {
        alert("Same Invoice ID already saved ");
        return;
      }
    } else {
      return;
    }
    // toggleLoadingModal();
  };
  const handlePrintButtonClick = () => {
    // either saved data or searched data in formData
    if (
      savedData.invoice_id === invoice_id ||
      formData.invoice_id === invoice_id ||
      editData.invoice_id === invoice_id
    ) {
      setPrintVisible(true);
      return;
    } else {
      if (formValid && validateInvoiceSheet) {
        setTimeout(() => {
          // Ensure the state is updated before calling submitHandler
          submitHandler();
        }, 0);
      }
    }
    //console.log("Button clicked");
  };

  const searchHandler = (searchInput) => {
    axios
      .get(`${process.env.REACT_APP_CRI_API}searchcustomers`, {
        params: {
          branch: branch,
          search: searchInput,
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        setSearchResults(res.data);
        //  customerDataHandler(res.data);
        if (mrn) {
          const temp = res.data.filter((item) => item.mrn === mrn);
        }
      })
      .catch((err) => {
        if (
          (err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      });
  };

  const searchCustomerHandler = (invoice) => {
    //token, searchInput, branch, navigate
    searchCustomers(
      userData.token,
      invoice.mrn,
      userData.branch,
      navigate
    ).then((res) => {
      if (res.length) {
        const [
          { fname, sname = "", sex = "", contactno, nationality, doctorName },
        ] = res;
        if (data) {
          setData({
            ...data,
            otData: {
              ...data.otData,
              fname: `${fname} ${sname}`,
              sex: sex,
              contactno: contactno,
              nationality,
              doctorName,
            },
          });
        }
        if (fname || sname) setDiscount(invoice.discount);
        setFormData({
          ...invoice,
          patientName: `${fname} ${sname}`,
          sex: sex,
          contactNo: contactno,
          nationality,
        });
      }
    });
  };

  const searchInvoiceHandler = (value) => {
    //  setEditData("");

    const invId =
      searchResult &&
      searchResult.find(
        (item) => item.invoice_id.toLowerCase() == value.toLowerCase()
      );

    if (value.length > 5 && !invId) {
      searchInvoices(userData.token, value, userData.branch, navigate).then(
        (res) => {
          setSearchResult(res);
        }
      );
    }
    if (invId) {
      setEditData(invId);
      searchCustomerHandler(invId);
      setDate(new Date(invId.invoice_date));
      // selected Invoice Id
      setInvoice_id(invId.invoice_id);
      const invoiceDate = new Date(invId.invoice_date); // Convert the invoice date to a Date object
      const currentDate = new Date(); // Get the current date

      // Calculate the difference in time (in milliseconds)
      const timeDifference = currentDate - invoiceDate;

      // Check if the time difference is less than 24 hours (24 * 60 * 60 * 1000 milliseconds)
      if (timeDifference < 24 * 60 * 60 * 1000 || userData.rights === "Admin") {
        // if(userData.rights === "Accounts")
        setEditMode(true);
      }

      //setMrn(invId.mrn);
      // selected MRN's invoice
      setSelectedDate(formatDate(invId.invoice_date));
      setInvoiceSheet(() => invId.invoice_sheet);
      if (data?.mode === "Return Mode") {
        //data?.mode === "Return Mode"
        setRtnInvoice_id(invId.rtn_invoice_id);
      }
    }
  };
  // after edit save,
  const filterNewItemsHandler = (oldSheet) => {
    // Extract items from oldSheet
    const oldItems = oldSheet.map((row) => Object.values(row)[0].item);

    // Filter invoiceSheet items that are not present in oldItems
    const filteredNewSheet = invoiceSheet.filter((row) => {
      const rowData = Object.values(row)[0];
      return !oldItems.includes(rowData.item) && rowData.item !== "";
    });

    // Find differences in quantities for items present in both sheets

    const updatedSheetWithDifferences = invoiceSheet.reduce(
      (acc, invoiceRow) => {
        const invoiceRowData = Object.values(invoiceRow)[0];
        const oldRow = oldSheet.find((row) => {
          const rowData = Object.values(row)[0];
          return rowData.item === invoiceRowData.item;
        });

        if (oldRow) {
          const oldRowData = Object.values(oldRow)[0];
          const qtyDifference =
            parseFloat(invoiceRowData.qty) - parseFloat(oldRowData.qty);

          if (qtyDifference !== 0) {
            acc.push({
              ...invoiceRow,
              [Object.keys(invoiceRow)[0]]: {
                ...invoiceRowData,
                qty: qtyDifference,
              },
            });
          }
        }

        return acc;
      },
      []
    );

    // Combine new items and updated quantities, and reformat row keys starting from Row1
    const combinedSheet = [...filteredNewSheet, ...updatedSheetWithDifferences];
    const formattedSheet = combinedSheet
      .filter((row) => {
        const rowData = Object.values(row)[0];
        return rowData.item !== "" && rowData.qty !== null;
      })
      .map((row, index) => {
        const rowData = Object.values(row)[0];
        return {
          [`Row${index + 1}`]: rowData,
        };
      });

    // Handle the filtered new items and updated quantities by updating the editUpdateSheet
    const cleanSheet = handleSheet(formattedSheet);
    setInvoiceSheetEditBill(formattedSheet);

    const updatedInvoiceStockSheet = prepareSheet(formattedSheet, itemsData);
    console.log(updatedInvoiceStockSheet);
    stockHandler(updatedInvoiceStockSheet);
    // Return the formatted sheet
    return formattedSheet;
  };

  // Function to handle updating the editUpdateSheet with new items
  const handleSheet = (filteredSheet) => {
    // Filter out null values and rows with empty or invalid data
    const validRows = filteredSheet.filter((row) => {
      if (!row) return false;
      const rowData = Object.values(row)[0];
      return (
        rowData.item ||
        (rowData.item !== "" &&
          rowData.qty !== null &&
          rowData.qty !== undefined)
      );
    });

    // Add new items from validRows
    return validRows.map((row, index) => {
      const rowData = Object.values(row)[0];
      return {
        [`Row${index}`]: rowData,
      };
    });
  };

  useEffect(() => {
    if (!invoice_id && !data?.invoiceSheetData)
      setInvoice_id(`INV-${Date.now()}`);
    // if (editData) {
    //   if (editData && data.invoiceSheetData.invoice_sheet && invoiceSheet) {
    //     console.log(data.invoiceSheetData.invoice_sheet);
    //     const newItemRow = compareSheets(
    //       data.invoiceSheetData.invoice_sheet,
    //       invoiceSheet
    //     );
    //     console.log(newItemRow, invoiceSheetEditBill);
    //     //   setInvoiceSheetEditBill(() => [...newItemRow, invoiceSheetEditBill]);
    //   }
    // }
  }, [invoiceSheet]);

  useEffect(() => {
    if (data?.mode === "Return Mode" && !rtnInvoice_id) {
      setRtnInvoice_id(`RTN-${Date.now()}`);
    }
    //data?.mode === "Return Mode"
  }, [data?.mode]);

  useEffect(() => {
    if (editData) {
      // setEditMode(true);
      const invoiceDate = new Date(editData.invoice_date); // Convert the invoice date to a Date object
      const currentDate = new Date(); // Get the current date

      // Calculate the difference in time (in milliseconds)
      const timeDifference = currentDate - invoiceDate;

      // Check if the time difference is less than 24 hours (24 * 60 * 60 * 1000 milliseconds)
      if (timeDifference < 24 * 60 * 60 * 1000 || userData.rights === "Admin") {
        setEditMode(true);
      }
    }
  }, [editData]);

  return (
    <Container className="pb-0">
      <Row className="d-flex justify-content-between p-2">
        {
          //    <Col md={3}>
          //    <Input
          //      style={{ marginRight: "20px" }}
          //      type="text"
          //      list="search"
          //      disabled={data && data.mode === "Return"}
          //      placeholder="Search Invoice"
          //      onChange={(e) => {
          //        setInvoice_id("");
          //        setInvoiceSheet(() => [
          //          {
          //            Row1: {
          //              item: "",
          //              qty: "",
          //              rate: 0,
          //              tax: 0,
          //              amount: 0,
          //              cgst: 9,
          //              sgst: 9,
          //            },
          //          },
          //          {
          //            Row2: {
          //              item: "",
          //              qty: "",
          //              rate: 0,
          //              tax: 0,
          //              amount: 0,
          //              cgst: 9,
          //              sgst: 9,
          //            },
          //          },
          //          {
          //            Row3: {
          //              item: "",
          //              qty: "",
          //              rate: 0,
          //              tax: 0,
          //              amount: 0,
          //              cgst: 9,
          //              sgst: 9,
          //            },
          //          },
          //          {
          //            Row4: {
          //              item: "",
          //              qty: "",
          //              rate: 0,
          //              tax: 0,
          //              amount: 0,
          //              cgst: 9,
          //              sgst: 9,
          //            },
          //          },
          //          {
          //            Row5: {
          //              item: "",
          //              qty: "",
          //              rate: 0,
          //              tax: 0,
          //              amount: 0,
          //              cgst: 9,
          //              sgst: 9,
          //            },
          //          },
          //          {
          //            Row6: {
          //              item: "",
          //              qty: "",
          //              rate: 0,
          //              tax: 0,
          //              amount: 0,
          //              cgst: 9,
          //              sgst: 9,
          //            },
          //          },
          //        ]);
          //        setFormData(() => {
          //          return {
          //            mrn: "",
          //            patientName: "",
          //            sex: "",
          //            contactNo: "",
          //            nationality: "",
          //          };
          //        });
          //        clearForm();
          //        searchInvoiceHandler(e.target.value);
          //      }}
          //    />
          //    <datalist id="search">
          //      {searchResult &&
          //        searchResult.map((item, id) => {
          //          return <option value={item.invoice_id} key={id} />;
          //        })}
          //    </datalist>
          //    {
          //      //  <Button
          //      //    onClick={() => {
          //      //      console.log("update");
          //      //      //      if (searchResult && invoiceSheet) {
          //      //      //         toggle();
          //      //      //       } else {
          //      //      //         alert("No Matching Invoice ID");
          //      //      //       }
          //      //    }}
          //      //    style={{ display: "inline-block" }}
          //      //  >
          //      //    {" "}
          //      //    Return
          //      //  </Button>
          //    }
          //  </Col>
        }
        <Col md={3}>
          <InputGroup className="mb-4 ">
            <Input
              placeholder="search patient"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <InputGroupText style={{ background: "rgba(50, 0, 100, 0.7)" }}>
              {" "}
              <Search
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  if (searchInput) {
                    searchHandler(searchInput);
                    togglepatientListModal();
                  }
                }}
              />
            </InputGroupText>
          </InputGroup>
        </Col>

        <Col md={2} className="text-end">
          <div style={{ display: "inline-block" }} className="pe-4 mt-2">
            <b>User: </b> {username}
          </div>
          <ReactToPrint
            onBeforePrint={handlePrintButtonClick}
            trigger={() => <button className="glass-button">Print</button>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
      <Row
        className="pt-1 d-flex justify-content-start"
        style={{ backgroundColor: "white" }}
      >
        <Col
          md="3"
          sm="12"
          //  className="d-flex justify-content-between"
          className="d-flex flex-column align-items-center"
          style={{ height: "100px" }}
        >
          {userData.branch && userData.branch === "CLT" && (
            <img src={ci} alt="image" style={{ height: "80%" }} />
          )}
          <p> GSTIN: {GSTIN}</p>
        </Col>
        <Col
          md={6}
          sm={6}
          className="d-flex justify-content-center text-md ps-5 "
        >
          {address}
        </Col>
        <Col md="3" sm={2} className="text-md">
          <h6>
            <b>Invoice</b>
          </h6>
          <h6>
            <b>Invoice No:</b>
            {invoice_id}
          </h6>

          {
            // <b>Date :</b> {formatDate(date)}
          }

          <FormGroup className="d-flex align-items-center">
            <Label style={{ width: "60px" }} for="date">
              Date
            </Label>
            <Input
              style={{ width: "150px" }}
              type="date"
              name="invoice_date"
              id="date"
              disabled={data?.mode === "Return Mode"}
              // value={formattedDate}
              value={selectedDate}
              onChange={(e) => {
                //handleDate(e);
                setSelectedDate(e.target.value);
              }}
              placeholder="Enter invoice Date"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="pt-5 text-md">
        <Col sm={5}>
          <h6>
            Patient Name :{" "}
            {data ? (
              data.otData.fname
            ) : (
              <Input
                name="patientName"
                value={formData.patientName}
                style={{ width: "250px", display: "inline-block" }}
                onChange={handleChange}
              />
            )}
          </h6>
          <h6>
            Sex :{" "}
            {data ? (
              data.otData.sex
            ) : (
              <Input
                name="sex"
                value={formData.sex}
                style={{
                  width: "100px",
                  display: "inline-block",
                  marginLeft: "72px",
                }}
                onChange={handleChange}
              />
            )}
          </h6>
          <h6>
            MRN :{" "}
            {data ? (
              data.otData.mrn
            ) : (
              <Input
                name="mrn"
                value={formData.mrn}
                style={{
                  width: "250px",
                  display: "inline-block",
                  marginLeft: "62px",
                }}
                onChange={handleChange}
              />
            )}
          </h6>
        </Col>
        <Col sm={3} className="text-md">
          <h6>
            Contact No :{" "}
            {data ? (
              data.otData.contactno
            ) : (
              <Input
                name="contactNo"
                value={formData.contactNo}
                style={{ width: "200px", display: "inline-block" }}
                onChange={handleChange}
              />
            )}
          </h6>
          <h6>
            Nationality :{" "}
            {data ? (
              data.otData.nationality
            ) : (
              <Input
                name="nationality"
                value={formData.nationality}
                style={{
                  width: "200px",
                  display: "inline-block",
                  marginLeft: "4px",
                }}
                onChange={handleChange}
              />
            )}
          </h6>
          <h6> Branch : {branch}</h6>{" "}
        </Col>
        <Col>
          <h6>
            Doctor :{" "}
            {data ? (
              data.otData.doctorName
            ) : (
              <Input
                name="doctorName"
                value={formData.doctorName}
                style={{ width: "250px", display: "inline-block" }}
                onChange={handleChange}
              />
            )}
          </h6>
          {data?.mode === "Return Mode" && (
            <FormGroup className="d-flex align-items-center">
              <Label style={{ width: "110px" }} for="date">
                Return Date
              </Label>
              <Input
                style={{ width: "150px" }}
                type="date"
                name="invoice_rtn_date"
                id="rtn-date"
                // value={formattedDate}
                value={selectedRtnDate}
                onChange={(e) => {
                  //handleDate(e);
                  setSelectedRtnDate(e.target.value);
                }}
                placeholder="Enter invoice return Date"
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md="2" className="text-center">
          <h4 className={data?.mode === "Return Mode" ? "text-danger" : ""}>
            {" "}
            {data?.mode === "Return Mode" ? "Return " : ""}Invoice {}
          </h4>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end"></Row>
      <Table bordered>
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: "#8b0f7a",
                width: "5%",
                textAlign: "center",
                border: "none",
                color: "white",
              }}
            >
              Sl No.
            </th>
            <th
              style={{
                backgroundColor: "rgb(126, 169, 255)",
                width: "40%",
                textAlign: "center",
                border: "none",
                color: "white",
                backgroundColor: "#8b0f7a",
              }}
            >
              ITEMS/SERVICES
            </th>
            {
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "10%",
                  textAlign: "center",
                  border: "none",
                  color: "white",
                  backgroundColor: "#8b0f7a",
                }}
              >
                QTY
              </th>
            }
            <th
              style={{
                backgroundColor: "rgb(126, 169, 255)",
                width: "10%",
                textAlign: "center",
                border: "none",
                color: "white",
                backgroundColor: "#8b0f7a",
              }}
            >
              RATE
            </th>
            {
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "10%",
                  textAlign: "center",
                  border: "none",
                  color: "white",
                  backgroundColor: "#8b0f7a",
                }}
              >
                TAX
              </th>
            }
            {
              <th
                style={{
                  backgroundColor: "rgb(126, 169, 255)",
                  width: "30%",
                  border: "none",
                  color: "white",
                  backgroundColor: "#8b0f7a",
                  textAlign: "center",
                }}
              >
                {" "}
                AMOUNT
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {itemsData.length &&
            invoiceSheet.map((row, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {row[`Row${index + 1}`].item && index + 1}
                  </td>
                  <td>
                    {
                      // userData.rights === "Admin" ||
                      // userData.rights === "Reception" ||
                      // userData.rights === "Accounts") && (
                      <Input
                        type="text"
                        list="data"
                        id="Row1item"
                        disabled={editData && !editMode}
                        autoComplete="off"
                        //  readOnly={MRN !== ""}
                        value={row[`Row${index + 1}`].item}
                        onChange={(e) => {
                          if (e.target.value) {
                            handleInputProcedure(e, index, "item", row);
                            return;
                          }
                          setInvoiceSheet((prevInvoiceSheet) => {
                            return prevInvoiceSheet.map((row, rowIndex) => {
                              if (index === rowIndex) {
                                return {
                                  ...row,
                                  [`Row${rowIndex + 1}`]: {
                                    ...row[`Row${rowIndex + 1}`],
                                    item: "",

                                    rate: "",
                                    qty: "",
                                    tax: "",
                                    amount: "",
                                  },
                                };
                              }
                              return row;
                            });
                          });
                          return;
                        }}
                      />
                    }
                    {(userData.rights === "Admin" ||
                      userData.rights === "Reception" ||
                      userData.rights === "Accounts") &&
                      itemsData.length && (
                        <datalist id="data">
                          {[
                            ...itemsData.reduce((acc, prod) => {
                              const existingItemIndex = acc.findIndex(
                                (accItem) =>
                                  accItem.item === prod.item &&
                                  prod.mrp === accItem.mrp
                              );

                              if (existingItemIndex === -1) {
                                // If the item does not exist in the accumulator array, add it
                                acc.push({ ...prod });
                              } else {
                                // If the item already exists, add the quantity
                                acc[existingItemIndex].quantity +=
                                  prod.quantity;
                              }

                              return acc;
                            }, []),
                            ...procedures,
                          ].map((item, id) => {
                            if (item.procedure) {
                              return (
                                <option
                                  key={id}
                                  value={item.procedure}
                                  data-item={item.procedure}
                                  data-cost={item.cost}
                                  data-tax="18"
                                />
                              );
                            } else {
                              return (
                                <option
                                  key={`${item.item}-${item.productId}`}
                                  value={`${item.item} ${item.quantity}`}
                                  data-item={item.item}
                                  data-cost={item.mrp}
                                  data-productid={item.productId}
                                  data-qty={item.quantity}
                                  data-tax={item.tax || "0"}
                                />
                              );
                            }
                          })}
                        </datalist>
                      )}
                    {
                      //   userData.rights === "Accounts" && (
                      //   <Input
                      //     type="text"
                      //     list="data"
                      //     id="Row1item"
                      //     //  readOnly={MRN !== ""}
                      //     value={row[`Row${index + 1}`].item}
                      //     onChange={(e) => {
                      //       handleInputProcedure(e, index, "item", row);
                      //     }}
                      //   />
                      // )
                    }
                    {
                      // userData.rights === "Accounts" && (
                      //  <datalist id="data">
                      //    {itemsData.length &&
                      //      itemsData.map((item, id) => {
                      //        return (
                      //          <option
                      //            key={id}
                      //            value={item.item}
                      //            data-cost={item.mrp}
                      //            data-productid={item.productId}
                      //            data-qty={item.quantity}
                      //            data-tax={item.tax || "0"}
                      //          />
                      //        );
                      //      })}
                      //    </datalist>
                      //  )
                    }
                  </td>

                  {
                    <td>
                      <Input
                        style={{ textAlign: "center" }}
                        id="Row1qty"
                        type="number"
                        disabled={editData && !editMode}
                        min="1"
                        name="qty"
                        value={
                          row[`Row${index + 1}`].qty
                            ? row[`Row${index + 1}`].qty
                            : ""
                        }
                        onChange={(e) => {
                          if (
                            row[`Row${index + 1}`].productId !== null &&
                            Number(row[`Row${index + 1}`].stockQty) >=
                              Number(e.target.value)
                          ) {
                            qtyHandler(e, index, "qty", row);
                          } else if (
                            row[`Row${index + 1}`].item &&
                            row[`Row${index + 1}`].productId === null
                          ) {
                            qtyHandler(e, index, "qty", row);
                          } else if (!row[`Row${index + 1}`].item) {
                            qtyHandler(e, index, "qty", row);
                          } else {
                            alert("Insufficient Stock");
                          }
                        }}
                      />
                    </td>
                  }
                  <td>
                    <Input
                      id="Row1rate"
                      type="text"
                      name="input1"
                      disabled={editData && !editMode}
                      value={
                        row[`Row${index + 1}`].rate
                          ? row[`Row${index + 1}`].rate
                          : ""
                      }
                      onChange={(e) => handleInputChange(e, index, "rate", row)}
                    />
                  </td>
                  {
                    <td>
                      <Input
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "8px",
                        }}
                        id="Row1tax"
                        // list="tax"
                        type="text"
                        name="tax"
                        disabled={editData && !editMode}
                        value={
                          row[`Row${index + 1}`].tax > 0
                            ? row[`Row${index + 1}`].tax
                            : ""
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, "tax", row)
                        }
                      />
                    </td>
                  }
                  {
                    <td>
                      <Input
                        id="Row1amount"
                        type="text"
                        disabled={editData && !editMode}
                        name="amount"
                        value={
                          row[`Row${index + 1}`].amount
                            ? row[`Row${index + 1}`].amount
                            : ""
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, "amount", row)
                        }
                      />
                    </td>
                  }
                </tr>
              );
            })}

          <tr scope="row">
            <td colSpan={1} style={{ textAlign: "center", cursor: "pointer" }}>
              {!editMode && !editData && <Plus onClick={addRow} />}
            </td>
            <td
              style={{
                textAlign: "end",
                fontWeight: "bold",
                paddingTop: "15px",
              }}
            >
              Total
            </td>
            <td style={{ textAlign: "center" }}>{totalQTY}</td>
            <td></td>
            <td style={{ textAlign: "center" }}>{totalTax}</td>
            <td> {total}</td>
          </tr>

          {
            // <tr scope="row">
            //  <td colSpan={1}></td>
            //  <td
            //    style={{
            //      textAlign: "end",
            //      fontWeight: "bold",
            //      paddingTop: "15px",
            //    }}
            //  >
            //    Tax %
            //  </td>
            //  <td>
            //    {" "}
            //    <Input
            //      value={tax ? tax : ""}
            //      onChange={(e) => {
            //        const value = e.target.value;
            //        const num = validator.isNumeric(value, {
            //          no_symbols: false,
            //        });
            //
            //        const decimal = validator.isFloat(value, {
            //          no_symbols: false,
            //        });
            //        if (decimal || num) {
            //          setTax(() => parseFloat(e.target.value));
            //        } else if (value === "-" || value === "") {
            //          setTax(0);
            //        }
            //      }}
            //    />
            //  </td>
            // </tr>
          }
          <tr scope="row">
            <td
              colSpan={5}
              style={{
                textAlign: "end",
                fontWeight: "bold",
                paddingTop: "15px",
              }}
            >
              Discount
            </td>
            <td>
              {" "}
              <Input
                onChange={(e) => setDiscount(e.target.value)}
                value={discount}
                disabled={editData && !editMode}
              />
            </td>
          </tr>
          {data?.mode !== "Return Mode" && (
            <tr scope="row">
              <td
                colSpan={5}
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  paddingTop: "15px",
                }}
              >
                Net Amount Payable
              </td>
              <td>
                {" "}
                <Input readOnly value={netAmountPay} />
              </td>
            </tr>
          )}
          {data?.mode !== "Return Mode" && (
            <tr>
              <td
                colSpan={5}
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  paddingTop: "15px",
                }}
              >
                Received Amount
              </td>
              <td>
                <Input
                  disabled={editData && !editMode}
                  onChange={(e) => {
                    if (isNaN(e.target.value)) {
                      alert("Please enter Numeric");
                      return;
                    }
                    setReceivedAmt(e.target.value);
                  }}
                  value={receivedAmt !== NaN ? receivedAmt : ""}
                />
              </td>
            </tr>
          )}
          {data?.mode !== "Return Mode" && (
            <tr>
              <td
                colSpan={5}
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  paddingTop: "15px",
                }}
              >
                Balance Amount
              </td>
              <td>{balanceAmount}</td>
            </tr>
          )}
          {
            //  data.mode === "Return Mode" && (
            //  <tr>
            //    <td
            //      colSpan={5}
            //      style={{
            //        textAlign: "end",
            //        fontWeight: "bold",
            //        paddingTop: "15px",
            //      }}
            //    >
            //      Adjusted Amount
            //    </td>
            //    <td>{}</td>
            //  </tr>
            //  )
          }
        </tbody>
      </Table>
      <Row>
        <Col md={6}>
          <PaymentModes
            payments={payments}
            setPayments={setPayments}
            disableToggle={editData && !editMode}
          />
        </Col>

        {
          //  <Col style={{ maxWidth: "150px" }}>
          //    <Label>Payment Mode</Label>
          //  </Col>
        }
        {
          //  <Col md="3">
          //    <Input
          //      id="paymode"
          //      type="text"
          //      list="paymentmode"
          //      name="input1"
          //      value={payment_mode}
          //      onChange={(e) => setPayment_mode(e.target.value)}
          //    />
          //    <datalist id="paymentmode">
          //      <option value="UPI" />
          //      <option value="Cash" />
          //      <option value="Credit Card" />
          //      <option value="Debit Card" />
          //      <option value="Cheque" />
          //      <option value="Net Banking" />
          //    </datalist>
          //  </Col>
        }
        <Col
          md={6}
          style={{ textAlign: "right" }}
          className="d-flex justify-content-between"
        >
          {!editMode && !editData && (
            <FormGroup style={{ width: "300px", textAlign: "left" }}>
              <Label for="remarks">
                <b>Remarks</b>
              </Label>
              <Input
                type="textarea"
                id="remarks"
                rows="2"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Enter your remarks here"
              />
            </FormGroup>
          )}
          <div>
            {!editMode && !editData && (
              <button
                className="me-2 glass-button"
                onClick={() => {
                  const x = window.confirm(
                    "Confirm to cancel the invoice entry"
                  );
                  if (x === true) {
                    clearForm();
                  }
                }}
              >
                Clear
              </button>
            )}

            {!editMode && !editData && (
              <button
                className="glass-button"
                onClick={() => {
                  setTimeout(() => {
                    // Ensure the state is updated before calling submitHandler
                    submitHandler();
                  }, 0);
                }}
              >
                Save Invoice
              </button>
            )}
            {editMode && editData && data?.mode !== "Return Mode" && (
              <button
                className="glass-button"
                onClick={() => {
                  // toggleLoadingModal();
                  editHandler();
                }}
              >
                Edit & Save Invoice
              </button>
            )}
            {editMode &&
              editData &&
              data?.mode === "Return Mode" &&
              !editData.rtn_invoice_id && (
                <button
                  className="glass-button"
                  onClick={() => {
                    // toggleLoadingModal();
                    returnHandler();
                  }}
                >
                  Save Return Invoice
                </button>
              )}
          </div>
        </Col>
      </Row>
      This is a computer-generated invoice. No signature is required.
      <Row>
        <Col className="ps-5"></Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={2} className="text-end pe-3">
          <ReactToPrint
            onBeforePrint={handlePrintButtonClick}
            trigger={() => (
              <button
                className="glass-button"
                onClick={() => {
                  setButtonHide({ display: "none" });
                }}
              >
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md="1">
          <button
            className="glass-button"
            onClick={() => {
              //if (userData.rights === "Accounts") navigate(-1);
              navigate(-1);
              // navigate(`${userData.branch === "CLT" ? "/crm-clt" : "/crm"}`);
            }}
          >
            Back
          </button>
        </Col>
      </Row>
      <div style={{ display: "none" }}>
        <PrintBill
          ref={componentRef}
          data={data}
          address={addressPrint}
          invoice_id={invoice_id}
          date={date}
          invoiceSheet={invoiceSheet}
          total={total}
          payment_mode={payments.map((payment) => payment.mode).join(", ")}
          branch={branch}
          buttonHide={buttonHide}
          tax={tax}
          netAmountPay={netAmountPay}
          GSTIN={GSTIN}
          discount={discount}
          receivedAmt={receivedAmt}
          balanceAmount={balanceAmount}
          totalQTY={totalQTY}
          totalTax={totalTax}
          taxableData={taxableData}
          formData={formData}
        />
      </div>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>{formData && "Edit Supplier"}</ModalHeader>
        <ModalBody>
          <Return invoiceSheet={invoiceSheet} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={loadingModal}
        toggle={toggleLoadingModal}
        centered
        {...args}
      >
        <ModalHeader toggle={toggleLoadingModal}>Processing </ModalHeader>
        <ModalBody className="text-center">
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
          <p className="mt-2">Please wait while your data is being saved...</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleLoadingModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={patientListModal}
        toggle={togglepatientListModal}
        {...args}
        style={{ maxWidth: "55%" }}
      >
        <ModalHeader toggle={togglepatientListModal}>
          Patient Search Result
        </ModalHeader>
        <ModalBody className="text-center">
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}> Name</th>
                <th>Contact No</th>
                <th>Address</th>
                <th>Treatment</th>
                <th>MRN</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {searchResults &&
                searchResults.map((cm, id) => {
                  return (
                    <tr key={id}>
                      <td style={{ textAlign: "left" }}>
                        {cm.fname} {cm.sname}
                      </td>
                      <td>{cm.contactno}</td>
                      <td
                        style={{
                          textAlign: "left",
                          maxWidth: "200px",
                          wordWrap: "break-word",
                        }}
                      >
                        {cm.address}
                      </td>
                      <td style={{ textAlign: "left" }}>{cm.treatmenttype}</td>
                      <td style={{ textAlign: "left" }}>{cm.mrn}</td>
                      <td>
                        <Input
                          type="radio"
                          name="select"
                          checked={selectedCM?.mrn === cm?.mrn}
                          onChange={() => {
                            setSelectedCM(cm);
                            setFormData({
                              mrn: cm.mrn,
                              patientName: `${cm.fname} ${cm.sname}`,
                              sex: cm.sex,
                              contactNo: cm.contactno,
                              nationality: cm.nationality,
                              doctorName: cm.doctorName,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <button onClick={togglepatientListModal}>Close</button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default BillEntry;
