import { useEffect, useState, React, Suspense, lazy } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
//import ReportChart from "../CRM-Calicut/Reports/ReportChart";

import "./styles/style.css";
import * as XLSX from "xlsx";

import {
  getAppointments,
  getCustomers,
  getInvoices,
  getPharmacyInvoices,
} from "../../../Data/Customer/api";
import { RefreshCcw } from "react-feather";
import DateSelection from "../../SubComponents/DateSelection";
const ReportChart = lazy(() => import("../CRM-Calicut/Reports/ReportChart"));
const CRMReports = () => {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState([]);
  const location = useLocation();
  const branchData = location.state;
  const [token] = useState(
    branchData ? branchData.token : JSON.parse(sessionStorage.getItem("token"))
  );
  const [patientRegistrionData, setPatientRegistrionData] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const date = new Date();
  const datewithoutHours = new Date(date.setHours(0, 0, 0, 0)).toISOString();
  const startDay = new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(); // searching purpose

  // const utcDate = selectedDate.toISOString(); // saving pupose
  let patientCount = 0;
  const branch = sessionStorage.getItem("branch")
    ? JSON.parse(sessionStorage.getItem("branch"))
    : "";
  const [todaysAppointment, setTodaysAppointment] = useState("");
  const [todaysInvoices, setTodaysInvoices] = useState("");
  const [invoicesData, setInvoicesData] = useState("");
  const [totalConsultationPaid, setTotalConsultationPaid] = useState("");
  const [totalProceduresPaid, setTotalProceduresPaid] = useState("");
  const [todaysPharmacyInvoices, setTodaysPharmacyInvoices] = useState("");
  const [totalPharmacyInvoice, setTotalPharmacyInvoice] = useState("");
  const [rotationAngle, setRotationAngle] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const [customerDataDownload, setCustomerDataDownload] = useState("");

  const startAnimation = () => {
    setIsSpinning(true);
    setTimeout(() => setIsSpinning(false), 4000); // Stop animation after 4 seconds
  };

  const exportToExcel = (data) => {
    // console.log(data);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "my-file.xlsx");
  };

  const dateSubmitHandler = () => {
    if (fromDate && toDate) {
      // token, selectedDate,branch,username, frmDate, navigate, toDate
      getInvoicesHandler("", fromDate, toDate);
      //date, frmD, toD
      getCustomersHandler("", fromDate, toDate);
    }
    if (selectedDate) {
      const dateFrmt = new Date(selectedDate);
      getCustomersHandler(selectedDate);
      getAppointmentsHandler(
        new Date(dateFrmt.setHours(0, 0, 0, 0)).toISOString()
      );
      getInvoicesHandler(new Date(dateFrmt.setHours(0, 0, 0, 0)).toISOString());
    }
  };
  const getCustomersHandler = (date, frmD, toD) => {
    // Create a new Date object for the current date and time in IST
    const currentDateIST = new Date(date);

    // Set the time components of the current date to represent the start of the day in IST
    currentDateIST.setHours(0, 0, 0, 0);

    // Get the ISO 8601 string representation of the start of the day in IST
    const startOfDayIST = currentDateIST.toISOString();

    setLoading(true);
    // rotateIcon();
    // getCustomers = (branch, mrn, doctorName, date);
    getCustomers(token, branch, "", "", startOfDayIST, navigate, frmD, toD)
      .then((res) => {
        // setLoading(false);

        if (res) {
          setPatientRegistrionData(res);
          return res.map((item, id) => {
            return {
              SL_No: id + 1,
              MRD_No: item.mrn,
              Patient_Name: `${item.fname} ${item.sname}`,
              Mobile: item.contactno,
              Address: item.address,
              District: item.district,
              Doctor: item.doctorName,
              Category: item.category,
              Procedure: item.treatmenttype,
              Session: item.patientSession,
              Visit_Type: item.additional?.visitType,
              Package_GST: "",
              Net: "",
            };
          });
        }
      })
      .then((arr) => {
        if (arr) setCustomerDataDownload(arr);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // if (selectedDate) {
  // newSelectedDate = new Date(selectedDate);
  //date = new Date(newSelectedDate.setHours(0, 0, 0, 0)).toISOString();
  // }
  const getInvoicesHandler = (datewithoutHours, frmDate, toDate) => {
    // token, selectedDate,branch,username, frmDate, navigate, toDate
    getInvoices(
      token,
      datewithoutHours,
      branch,
      "",
      frmDate,
      navigate,
      toDate
    ).then((res) => {
      setInvoicesData(res);
      //console.log(res);
      if (res) {
        //  setTotalConsultationPaid(() => {
        //    const totalAmountPaidArray = res.map(
        //      (item) => item.total_amount_paid
        //    );
        //    const totalAmountPaid = totalAmountPaidArray.reduce(
        //      (total, amount) => parseFloat(total) + parseFloat(amount),
        //      0
        //    );
        //    return totalAmountPaid;
        //  });

        // filter and updated Patient invoice
        const filterInvoices = res.map((invoice) => {
          // Filter items without productId
          const itemsWithoutProductId = invoice.invoice_sheet.filter(
            (row) =>
              row[Object.keys(row)].productId === null &&
              row[Object.keys(row)].item?.toLowerCase().includes("consult")
          );

          const totalPatientAmountPaid = itemsWithoutProductId.reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
            0
          );
          // Check if totalPatientAmountPaid is a number before using toFixed
          const totalPatientAmountPaidFormatted =
            typeof totalPatientAmountPaid === "number"
              ? totalPatientAmountPaid.toFixed(2)
              : totalPatientAmountPaid;
          // Calculate total amount Pharmacy
          const totalPatientAmount = itemsWithoutProductId.reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
            0
          );

          // Check if totalPatientAmount is a number before using toFixed
          const totalPatientAmountFormatted =
            typeof totalPatientAmount === "number"
              ? totalPatientAmount.toFixed(2)
              : totalPatientAmount;

          const patientInvoice = {
            ...invoice,
            total_amount_paid: totalPatientAmountPaidFormatted,
            total_amount: totalPatientAmountFormatted,
            invoice_sheet: itemsWithoutProductId,
          };

          return patientInvoice;
        });
        // filter Pharmacy sales
        const filterPharmacyInvoices = res.map((invoice) => {
          // console.log(invoice);
          // Filter items with productId and calculate total amount paid
          const itemsWithProductId = invoice.invoice_sheet.filter(
            (row) => row[Object.keys(row)].productId !== null
          );

          const totalAmountPaid = itemsWithProductId.reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
            0
          );
          const totalAmountPaidFormatted =
            typeof totalAmountPaid === "number"
              ? totalAmountPaid.toFixed(2)
              : totalAmountPaid;
          // Calculate total amount Pharmacy
          const totalAmount = itemsWithProductId.reduce(
            (total, item) => total + item[Object.keys(item)].amount,
            0
          );
          const totalAmountFormatted =
            typeof totalAmount === "number"
              ? totalAmount.toFixed(2)
              : totalAmount;
          const pharmacyInvoice = {
            ...invoice,
            total_amount_paid: totalAmountPaidFormatted,
            total_amount: totalAmountFormatted,
            invoice_sheet: itemsWithProductId,
          };

          return pharmacyInvoice;
        });

        if (filterPharmacyInvoices && filterPharmacyInvoices.length > 0) {
          setTodaysPharmacyInvoices(() =>
            filterPharmacyInvoices.filter(
              (itm) => parseFloat(itm.total_amount) > 0
            )
          );
          setTotalPharmacyInvoice(() => {
            const totalAmountPaidArray = filterPharmacyInvoices
              .map((item) => item.total_amount_paid)
              .filter((value) => !isNaN(value));

            const totalAmountPaid = totalAmountPaidArray.reduce(
              (total, amount) => parseFloat(total) + parseFloat(amount),
              0
            );
            // console.log(filterInvoices[0]?.pharmacy);
            return totalAmountPaid;
          });
        }

        if (filterInvoices && filterInvoices.length > 0) {
          // console.log(filterInvoices);

          setTodaysInvoices(filterInvoices); // witoutProductId Invoice

          setTotalConsultationPaid(() => {
            const totalAmountPaidArray = filterInvoices
              .map((item) => item.total_amount_paid)
              .filter((value) => !isNaN(value));

            const totalAmountPaid = totalAmountPaidArray.reduce(
              (total, amount) => parseFloat(total) + parseFloat(amount),
              0
            );

            return totalAmountPaid;
          }); //same logics but without Product Ids total onvoice
        }
        const filterProceduresInvoices = res.map((invoice) => {
          // Filter items without productId
          const itemsWithoutProductId = invoice.invoice_sheet.filter(
            (row) =>
              row[Object.keys(row)].productId === null &&
              !row[Object.keys(row)].item?.toLowerCase().includes("consult")
          );

          const totalPatientAmountPaid = itemsWithoutProductId.reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
            0
          );
          // Check if totalPatientAmountPaid is a number before using toFixed
          const totalPatientAmountPaidFormatted =
            typeof totalPatientAmountPaid === "number"
              ? totalPatientAmountPaid.toFixed(2)
              : totalPatientAmountPaid;
          // Calculate total amount Pharmacy
          const totalPatientAmount = itemsWithoutProductId.reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
            0
          );

          // Check if totalPatientAmount is a number before using toFixed
          const totalPatientAmountFormatted =
            typeof totalPatientAmount === "number"
              ? totalPatientAmount.toFixed(2)
              : totalPatientAmount;

          const patientInvoice = {
            ...invoice,
            total_amount_paid: totalPatientAmountPaidFormatted,
            total_amount: totalPatientAmountFormatted,
            invoice_sheet: itemsWithoutProductId,
          };

          return patientInvoice;
        });

        setTotalProceduresPaid(() => {
          const totalAmountPaidArray = filterProceduresInvoices
            .map((item) => item.total_amount_paid)
            .filter((value) => !isNaN(value));

          const totalAmountPaid = totalAmountPaidArray.reduce(
            (total, amount) => parseFloat(total) + parseFloat(amount),
            0
          );

          return totalAmountPaid;
        }); //same logics but without Product Ids total onvoice
      }
    });
  };
  const getAppointmentsHandler = (date) => {
    //console.log(utcDate);
    //  token,
    //  selectedDate,
    //  branch,
    //  frmDate,
    //  doctorName,
    //  department,
    //  navigate
    getAppointments(token, date, branch, "", "", "", navigate).then((res) => {
      if (res) {
        setTodaysAppointment(res);
        return;
      }
    });
  };
  // const getPharmacyInvoicesHandler = () => {
  //   //token, selectedDate, branch, username, frmDate, navigate;
  //   getPharmacyInvoices(
  //     token,
  //     datewithoutHours,
  //     branch,
  //     null,
  //     null,
  //     navigate
  //   ).then((res) => {
  //     setTodaysPharmacyInvoices(res);
  //     if (res) {
  //       setTotalPharmacyInvoice(() => {
  //         const totalAmountPaidArray = res.map(
  //           (item) => item.total_amount_paid
  //         );
  //         console.log(totalAmountPaidArray);
  //         const totalAmountPaid = totalAmountPaidArray.reduce(
  //           (total, amount) => total + amount,
  //           0
  //         );

  //         return totalAmountPaid;
  //       });
  //     }
  //   });
  // };

  useEffect(() => {
    if (token && token !== "") {
      getCustomersHandler(new Date());
      getAppointmentsHandler(datewithoutHours);
      getInvoicesHandler(datewithoutHours);
      //  getPharmacyInvoicesHandler(); currently it is in one db
      setSelectedDate(new Date());
    }
    if (!token) {
      navigate("/");
    }
  }, [branch, token]);
  //  format date to show
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  // const formatDate = (dte) => {
  //   const fdate = new Date(dte);
  //
  //   const ISTOffset = 330; // IST offset in minutes
  //   const ISTTime = new Date(fdate.getTime() + ISTOffset * 60000); // IST time
  //   const formattedDate = ISTTime.toISOString().split("T")[0];
  //
  //   return formattedDate;
  // };
  const flattenInvoiceHandler = (invoiceData) => {
    const flattenedInvoices = invoiceData.map((invoice) => {
      const flattenedInvoiceSheet = invoice.invoice_sheet
        .map((item) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          if (value.item !== "") {
            return { ...value, key };
          }
          return null;
        })
        .filter((item) => item !== null)
        .reduce((acc, item) => {
          const rowNumber = item.key.replace("Row", ""); // Extract row number
          Object.entries(item).forEach(([subKey, subValue]) => {
            acc[`Row${rowNumber}${subKey}`] = subValue; // Flatten Row item properties
          });
          return acc;
        }, {});

      return {
        ...invoice,
        ...flattenedInvoiceSheet,
      };
    });
    return flattenedInvoices;
  };

  const flattenInvoiceMIS = (invoiceData) => {
    const flattenedInvoices = invoiceData.flatMap((invoice) => {
      // Flatten each item in invoice_sheet
      return invoice.invoice_sheet
        .map((row, id) => {
          const key = Object.keys(row)[0]; // Get row key (e.g., Row1, Row2, etc.)
          const value = row[key]; // Extract the row's data object

          if (value.item) {
            return {
              SLNo: id + 1,
              Date: new Date(invoice.invoice_date).toLocaleDateString(),
              PatientName: invoice.patientName,
              MobileNo: "",
              MRN: invoice.mrn,
              Doctor: invoice.doctorName,
              InvoiceID: invoice.invoice_id, // Add any relevant invoice-level fields
              Item: value.item,
              Quantity: value.qty,
              Rate: value.rate,
              Tax: value.tax,
              Amount: parseFloat(value.amount), // made to parseFloat to see in excel as numbers
              PaymentMode: invoice.payment_mode
                .map(
                  (item) => `Mode: ${item.mode}, Amount: ${item.amount || "0"}`
                )
                .join("; "),
            };
          }
          return null;
        })
        .filter((item) => item !== null); // Filter out any null items
    });

    return flattenedInvoices;
  };

  return (
    <Container className="pb-5">
      <Row
        className="text-white p-2 mt-2 mb-2"
        style={{
          borderRadius: "3px",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)", // For Safari
          backgroundColor: "rgba(50, 0, 100, 0.7)", // Darker purple with opacity
        }}
      >
        <Col className="d-flex justify-content-center text-white ">
          {" "}
          <h6>Daily Reports - CLT</h6>
        </Col>
      </Row>

      <Row>
        <Col md="1" className="pb-4">
          <button
            // color="primary"
            // outline
            onClick={() =>
              navigate(`${branch === "CLT" ? "/crm-clt" : "/crm"}`)
            }
          >
            Back
          </button>
        </Col>
        <Col sm={2}>
          <button
            style={{ cursor: "pointer" }}
            onClick={() => {
              const flattenedData = flattenInvoiceMIS(invoicesData);

              exportToExcel(flattenedData);
            }}
          >
            MIS Download
          </button>
        </Col>
      </Row>
      <Row className="p-2">
        <Col sm={2}>
          <Card
            style={{
              width: "200px",
              backgroundColor: "#7746A8",
              color: "white",
              textAlign: "center",
            }}
          >
            <CardBody>
              <h6>
                Advance Collected <br />
                {
                  invoicesData
                    ? invoicesData.reduce((total, invoice) => {
                        // Filter out rows where `productId` is null and `item` contains "ht advance"
                        const htAdvanceRows = invoice.invoice_sheet.filter(
                          (row) => {
                            const key = Object.keys(row)[0]; // Access dynamic key
                            return (
                              row[key].productId === null &&
                              row[key].item
                                ?.toLowerCase()
                                .includes("ht advance")
                            );
                          }
                        );

                        // Calculate the total amount for HT advance rows
                        const htAdvanceAmount = htAdvanceRows.reduce(
                          (rowTotal, htRow) =>
                            rowTotal +
                              parseFloat(htRow[Object.keys(htRow)[0]].amount) ||
                            0,
                          0
                        );

                        return total + htAdvanceAmount;
                      }, 0)
                    : "" // Initial total is 0
                }{" "}
                Rs
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col sm={2}>
          <Card
            style={{
              width: "200px",
              backgroundColor: "#7746A8",
              color: "white",
              textAlign: "center",
            }}
          >
            <CardBody>
              <h6>
                Number of Invoices <br />
                {
                  invoicesData ? invoicesData.length : "" // Initial total is 0
                }{" "}
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col sm={2}>
          <Card
            style={{
              width: "200px",
              backgroundColor: "#7746A8",
              color: "white",
              textAlign: "center",
            }}
          >
            <CardBody>
              <h6>
                Total Pharmacy Sales{" "}
                {
                  totalPharmacyInvoice ? totalPharmacyInvoice : "" // Initial total is 0
                }{" "}
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col sm={2}>
          <Card
            style={{
              width: "200px",
              backgroundColor: "#7746A8",
              color: "white",
              textAlign: "center",
            }}
          >
            <CardBody>
              <h6>
                Total Consultation{" "}
                {
                  totalConsultationPaid ? totalConsultationPaid : "" // Initial total is 0
                }{" "}
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col sm={2}>
          <Card
            style={{
              width: "200px",
              backgroundColor: "#7746A8",
              color: "white",
              textAlign: "center",
            }}
          >
            <CardBody>
              <h6>
                Total Procedures{" "}
                {
                  totalProceduresPaid ? totalProceduresPaid : "" // Initial total is 0
                }{" "}
              </h6>
            </CardBody>
          </Card>
        </Col>
        {
          //  <Col sm={2}>
          //    <Card
          //      style={{
          //        width: "200px",
          //        backgroundColor: "#7746A8",
          //        color: "white",
          //        textAlign: "center",
          //      }}
          //    >
          //      <CardBody>
          //        <h6>
          //          Total Procedures{" "}
          //          {
          //            totalConsultationPaid ? totalConsultationPaid : "" // Initial total is 0
          //          }{" "}
          //        </h6>
          //      </CardBody>
          //    </Card>
          //  </Col>
          //  <Col sm={2}>
          //    <Card
          //      style={{
          //        width: "200px",
          //        backgroundColor: "#7746A8",
          //        color: "white",
          //        textAlign: "center",
          //      }}
          //    >
          //      <CardBody>
          //        <h6>
          //          Total Lab <br />
          //          {
          //            totalConsultationPaid ? totalConsultationPaid : "" // Initial total is 0
          //          }{" "}
          //        </h6>
          //      </CardBody>
          //    </Card>
          //  </Col>
        }
      </Row>
      <Row className="mt-2 mb-2">
        <Col sm={6}>
          <DateSelection
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            dateSubmitHandler={dateSubmitHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-center" md={6}>
          <Card className="pt-3">
            <CardTitle>
              <b>New Patient Registration</b>
            </CardTitle>
            <CardBody>
              <Suspense fallback={<div>Loading ReportChart...</div>}>
                <ReportChart
                  data={patientRegistrionData}
                  patientCount={patientCount}
                  chartName="Patient Registration"
                  selectedDate={selectedDate}
                />
              </Suspense>
            </CardBody>
          </Card>
        </Col>
        <Col className="text-center" md={6}>
          <Card className="pt-3">
            <CardTitle>
              <b>Today's Appointments</b>
            </CardTitle>
            <CardBody>
              <Suspense fallback={<div>Loading ReportChart...</div>}>
                <ReportChart
                  data={todaysAppointment}
                  chartName="Today's Appointment"
                  patientCount={patientCount}
                  selectedDate={selectedDate}
                />
              </Suspense>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="text-center" md={6}>
          <Card className="mt-3 pt-3">
            <CardTitle>
              <b>Today's Consultation Fees Recieved</b>
            </CardTitle>
            <CardBody>
              <Suspense fallback={<div>Loading ReportChart...</div>}>
                <ReportChart
                  data={todaysInvoices}
                  chartName="Today's Patient Invoice"
                  total={totalConsultationPaid}
                  selectedDate={selectedDate}
                />
              </Suspense>
            </CardBody>
          </Card>
        </Col>
        <Col className="text-center" md={6}>
          <Card className="mt-3 pt-3">
            <CardTitle>
              <b>Today's Pharmacy Sales</b>
            </CardTitle>
            <CardBody>
              <Suspense fallback={<div>Loading ReportChart...</div>}>
                <ReportChart
                  data={todaysPharmacyInvoices}
                  chartName="Today's Pharmacy Invoice"
                  total={totalPharmacyInvoice}
                  selectedDate={selectedDate}
                />
              </Suspense>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-3 pt-3">
        <Col sm={4} style={{ textAlign: "center" }}>
          <h5>Today's Patient Registration Details</h5>{" "}
        </Col>
        <Col sm={1}>
          <RefreshCcw
            className={` ${isSpinning ? "rotate" : ""}`}
            style={{
              cursor: "pointer",
            }}
            color="green"
            onClick={() => {
              getCustomersHandler(selectedDate);
              startAnimation();
            }}
          />
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Date</th>
            <th>Patient Name</th>
            <th>Contact No</th>
            <th>Address</th>
            <th>Visit Type</th>
            <th>Treatment Type</th>
          </tr>
        </thead>
        <tbody>
          {patientRegistrionData &&
            patientRegistrionData.length > 0 &&
            patientRegistrionData.map((cm, id) => {
              return (
                <tr key={id}>
                  <td>{Number(id) + 1}</td>
                  <td>{new Date(cm.date).toLocaleDateString()}</td>
                  <td>{cm.fname + cm.sname}</td>
                  <td>{cm.contactno}</td>
                  <td>{cm.address}</td>
                  <td>{cm.additional?.visitType}</td>
                  <td>{cm.treatmenttype}</td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={7}
              style={{
                backgroundColor: "transparent",

                textAlign: "end",
              }}
            >
              {patientRegistrionData.length > 0 && (
                <button onClick={() => exportToExcel(customerDataDownload)}>
                  Download
                </button>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      <Row className="d-flex justify-content-center pt-3">
        <Col sm={4} style={{ textAlign: "center" }}>
          <h5>Today's Patient Appointment Details</h5>
        </Col>
        <Col sm={1}>
          <RefreshCcw
            style={{ cursor: "pointer" }}
            color="green"
            onClick={getAppointmentsHandler}
          />
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>MRN</th>
            <th>Appointment Date</th>
            <th>Doctor</th>
          </tr>
        </thead>
        <tbody>
          {todaysAppointment &&
            todaysAppointment.map((cm, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{cm.mrn}</td>
                  <td>{new Date(cm.appointmentdate).toLocaleDateString()}</td>
                  <td>{cm.doctor}</td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={6}
              style={{
                backgroundColor: "transparent",

                textAlign: "end",
              }}
            >
              {todaysAppointment.length > 0 && (
                <button onClick={() => exportToExcel(todaysAppointment)}>
                  Download
                </button>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      <Row className="d-flex justify-content-center pt-3">
        <Col sm={4} style={{ textAlign: "center" }}>
          <h5>Today's Patient Consultation Fee Details</h5>
        </Col>
        <Col sm={1}>
          <RefreshCcw
            style={{ cursor: "pointer" }}
            color="green"
            onClick={getInvoicesHandler}
          />
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Invoice Date</th>
            <th>MRN</th>
            <th>Payment Mode</th>
            <th>Invoice ID</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {todaysInvoices &&
            todaysInvoices.map((cm, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{new Date(cm.invoice_date).toLocaleDateString()}</td>
                  <td>{cm.mrn}</td>
                  <td>
                    {Array.isArray(cm.payment_mode)
                      ? cm.payment_mode
                          .map((pm) => `${pm.mode}: ${pm.amount}`)
                          .join(", ")
                      : cm.payment_mode}
                  </td>
                  <td>{cm.invoice_id}</td>
                  <td>{cm.total_amount_paid}</td>
                </tr>
              );
            })}
          <tr>
            <td colSpan={5} style={{ textAlign: "right" }}>
              {" "}
              <b>Total</b>
            </td>
            <td>
              <b>{totalConsultationPaid}</b>{" "}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={6}
              style={{
                backgroundColor: "transparent",

                textAlign: "end",
              }}
            >
              {todaysInvoices.length > 0 && (
                <button
                  onClick={() => {
                    const data = flattenInvoiceHandler(todaysInvoices);
                    exportToExcel(data);
                  }}
                >
                  Download
                </button>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      <Row className="d-flex justify-content-center pt-3">
        <Col sm={4} style={{ textAlign: "center" }}>
          <h5>Today's Pharmacy Details</h5>
        </Col>
        <Col sm={1}>
          <RefreshCcw
            style={{ cursor: "pointer" }}
            color="green"
            onClick={getInvoicesHandler}
          />
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Invoice Date</th>
            <th>MRN</th>
            <th>Payment Mode</th>
            <th>Invoice ID</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {todaysPharmacyInvoices &&
            todaysPharmacyInvoices.map((cm, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{new Date(cm.invoice_date).toLocaleDateString()}</td>
                  <td>{cm.mrn}</td>
                  <td>
                    {" "}
                    {Array.isArray(cm.payment_mode)
                      ? cm.payment_mode
                          .map((pm) => `${pm.mode}: ${pm.amount}`)
                          .join(", ")
                      : cm.payment_mode}
                  </td>
                  <td>{cm.invoice_id}</td>
                  <td>{cm.total_amount_paid}</td>
                </tr>
              );
            })}
          <tr>
            <td colSpan={5} style={{ textAlign: "right" }}>
              {" "}
              <b>Total</b>
            </td>
            <td>
              <b>{totalPharmacyInvoice}</b>{" "}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={6}
              style={{
                backgroundColor: "transparent",

                textAlign: "end",
              }}
            >
              {todaysPharmacyInvoices.length > 0 && (
                <button
                  onClick={() => {
                    // Flatten invoice_sheet and filter objects with Row1Item
                    // Flatten invoice_sheet and filter objects with Row1Item
                    // Flatten invoice_sheet of each invoice and filter objects with Row1Item
                    const data = flattenInvoiceHandler(todaysPharmacyInvoices);
                    exportToExcel(data);
                  }}
                >
                  Download
                </button>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
    </Container>
  );
};

export default CRMReports;
